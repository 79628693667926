import { format } from 'date-fns'

import { ErrorTypes } from '.'
import Api from '../../utils/api'
import { FAILED_SIGNUP_KEY } from '../../utils/constants'

interface IResponse {
  ok: boolean
  error: number | undefined
  body: any | undefined
}

export const getOrderNode = async (orderId: string) => {
  const orderNodeResponse = await Api.post('/api/nodes/query', {
    filters: [
      {
        fieldName: 'uniqueId',
        operator: 'eq',
        value: `shp-${orderId}`,
      },
    ],
  })

  const returnObject: IResponse = {
    ok: true,
    error: undefined,
    body: undefined,
  }

  if (!orderNodeResponse.ok) {
    returnObject.error = ErrorTypes.API_ERROR
    returnObject.ok = false
  } else if (orderNodeResponse.body.results.length === 0) {
    returnObject.error = ErrorTypes.NOT_FOUND
    returnObject.ok = false
  } else {
    returnObject.body = orderNodeResponse.body.results[0]
  }

  return returnObject
}

export const getOrderChannelData = async (nodeId: string) => {
  const orderResponse = await Api.get(`/api/data/current?nodeId=${nodeId}`)

  const returnObject: IResponse = {
    ok: true,
    error: undefined,
    body: undefined,
  }

  if (!orderResponse.ok) {
    returnObject.error = ErrorTypes.API_ERROR
    returnObject.ok = false
  } else if (!orderResponse.body?.[nodeId]) {
    returnObject.error = ErrorTypes.NOT_FOUND
    returnObject.ok = false
  } else {
    returnObject.body = orderResponse.body?.[nodeId]
  }

  return returnObject
}

export const getDevicesFolderId = async (
  scannedBarcode: LoraV2BarcodeObject | LeakAndFreezeBarcodeObject | GatewayBarcodeObject | LoraV3BarcodeObject | CalyxBarcodeObject
) => {
  const filters = []

  // this function should be using the lookup_table located here
  // api/files/private/scan_lookup_table.csv
  // however only one gateway and one nbiot_leak sensor exists
  // in that file so we will hard code it for now

  const sn = scannedBarcode.sn.toLowerCase()
  if (scannedBarcode.sku === 'TK-KONA-WT1') {
    // gateway
    filters.push({
      fieldName: 'uniqueId',
      operator: 'eq',
      value: '200' + sn,
    })
    filters.push({
      fieldName: 'uniqueId',
      operator: 'eq',
      value: sn.substring(0, sn.length / 2) + 'fffe' + sn.substring(sn.length / 2),
    })
  } else if (scannedBarcode.sku === 'SC-LM-1' && scannedBarcode.type === 'leakAndFreeze') {
    // nbiot_leak
    filters.push({
      fieldName: 'uniqueId',
      operator: 'eq',
      value: scannedBarcode.imei.toLowerCase(),
    })
  } else {
    filters.push({
      fieldName: 'uniqueId',
      operator: 'eq',
      value: sn,
    })
    filters.push({
      fieldName: 'uniqueId',
      operator: 'eq',
      value: sn.match(/.{1,2}/g)?.join('-'), // puts a dash every two characters
    })
  }

  const response = await Api.post('/api/nodes/query', {
    filters,
    type: 'or',
  })

  return response?.body?.results?.[0]?.folderId as number
}

export const getDevicesOrder = async (folderId: number) => {
  const nodeResponse = await Api.post('/api/nodes/query', {
    filters: [
      {
        fieldName: 'folderId',
        operator: 'eq',
        value: folderId,
      },
      {
        fieldName: 'nodeTypeName',
        operator: 'eq',
        value: 'order',
      },
    ],
  })

  const nodeId = nodeResponse?.body?.results?.[0]?.id

  if (!nodeId) return undefined

  const order = await getOrderChannelData(nodeId)

  return order.body?.short_order_number?.value
}

export const getDevicesAccount = async (folderId: number) => {
  const response = await Api.get(`/api/folders/${folderId}`)

  return response.body?.name
}

export const getFolder = async (folderId: number) => {
  const response = await Api.get(`/api/folders/${folderId}`)

  const returnObject: IResponse = {
    ok: true,
    error: undefined,
    body: undefined,
  }

  if (!response.ok) {
    returnObject.error = response?.body
    returnObject.ok = false
  } else {
    returnObject.body = response.body as IFolderType
  }

  return returnObject
}

export const getLocationManager = async (folderId: number) => {
  const validContactRoleNames = ['Location Manager', 'Super Admin', 'HSB Admin', 'Admin - Analyst', 'Group Rep']

  const roles = await Api.get('/api/roles')
  if (!roles.ok) return undefined

  const validContactRoleIds = roles.body.filter((role: IRoleType) => validContactRoleNames.includes(role.name)).map((role: IRoleType) => role.id)
  const locationManagerId = roles.body.filter((role: IRoleType) => role.name === 'Location Manager')?.[0]?.id
  const filters = [
    { fieldName: 'folders', operator: 'any', value: [folderId] },
    { fieldName: 'roleId', operator: 'in', value: validContactRoleIds },
  ]

  const usersResponse = await Api.post(`/api/users/query`, { filters })
  if (!usersResponse.ok) return undefined

  const users = usersResponse.body.results as IUserType[]

  // location manager should be the primary return value
  const locationManager = users.find((user: IUserType) => user.roleId === locationManagerId)
  if (locationManager) {
    return locationManager as IUserType
  }

  // if no location manager is found, return first user
  return users?.[0] as IUserType
}

export const setFulfillmentOrderDone = async (nodeId: number, hashIds: string[], locationManagerId: number, addressCode: string) => {
  try {
    const requestOrderStatus = await Api.post('/api/publish/cloud', {
      channelName: 'order_status',
      nodeId,
      value: 'Fulfilled',
    })

    if (!requestOrderStatus.ok) {
      throw new Error('failed to update order_status')
    }

    const requestFulfilledDate = await Api.post('/api/publish/cloud', {
      channelName: 'fulfillment_date',
      nodeId,
      value: format(new Date(), 'LL/d/yyyy h:mm:ss a'),
    })

    if (!requestFulfilledDate.ok) {
      throw new Error('failed to update fulfillment_date')
    }

    if (locationManagerId !== 0 && addressCode !== '') {
      const authPromises = hashIds
        .map((hashId) => {
          if (!hashId || hashId === FAILED_SIGNUP_KEY) {
            return
          }

          return Api.post(`/api/authentication/usertoken?userId=${locationManagerId}`, {
            accessId: hashId.padStart(7, '0'),
            name: 'nbiot-signup-key',
            secret: addressCode,
          })
        })
        .filter((x) => x)

      const authResponses = await Promise.all(authPromises)

      const authResponseError = authResponses.find((res) => res && !res.ok)
      if (authResponseError) {
        throw new Error('failed to assign all access keys')
      }
    }

    return { ok: true, error: undefined }
  } catch (e) {
    // log message?
    return { ok: false, error: e }
  }
}
