import { Button, Card, CardContent, CardHeader, CardMedia, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'

import boxLogo from '../../images/box.svg'
import returnLogo from '../../images/return.svg'
import { SCANNER_CODES } from '../../utils/constants'
import useScannerCapture from '../../utils/custom-hooks/use-scanner-capture'
import FulfillDialog from './dialogs/fulfill-dialog'
import ReturnDialog from './dialogs/return-dialog'
import useStyles from './index.styles'

const Dashboard = () => {
  const classes = useStyles()
  const scannerHistory = useScannerCapture()

  const [showFulfillmentModal, setShowFulfillmentModal] = useState<boolean>(false)
  const [showReturnModal, setShowReturnModal] = useState<boolean>(false)

  useEffect(() => {
    const command = scannerHistory.slice(-1).pop()
    if (command === SCANNER_CODES.START_FULFILLMENT) {
      setShowFulfillmentModal(true)
    }
    if (command === SCANNER_CODES.START_RETURN) {
      setShowReturnModal(true)
    }
  }, [scannerHistory])

  return (
    <>
      <Grid container={true} spacing={5}>
        <Grid item={true} xs={12}>
          <Typography variant="h5">Start a Task</Typography>
        </Grid>

        <Grid item={true} xs={12} sm={12} md={6} lg={5} xl={5}>
          <Card>
            <Grid container={true} justifyContent="space-between">
              <Grid item={true} container={true} direction="column" xs={true}>
                <Grid item={true}>
                  <CardHeader title="Fulfill a new order" avatar={<img src={boxLogo} className={classes.avatarLogo} />} />
                </Grid>

                <Grid item={true} xs={true}>
                  <CardContent>Scan the QR code for auto process</CardContent>
                </Grid>

                <Grid item={true}>
                  <CardContent>
                    <Button
                      data-cy="startFulfillment"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setShowFulfillmentModal(true)
                      }}
                    >
                      Start fulfillment
                    </Button>
                  </CardContent>
                </Grid>
              </Grid>

              <Grid item={true}>
                <CardMedia>
                  <QRCode value={SCANNER_CODES.START_FULFILLMENT} level="M" size={192} className={classes.qrCode} />
                </CardMedia>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item={true} xs={12} sm={12} md={6} lg={5} xl={5}>
          <Card>
            <Grid container={true} justifyContent="space-between">
              <Grid item={true} container={true} direction="column" xs={true}>
                <Grid item={true}>
                  <CardHeader title="Return an existing order" avatar={<img src={returnLogo} className={classes.avatarLogo} />} />
                </Grid>

                <Grid item={true} xs={true}>
                  <CardContent>Scan the QR code for auto process</CardContent>
                </Grid>

                <Grid item={true}>
                  <CardContent>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setShowReturnModal(true)
                      }}
                    >
                      Start return
                    </Button>
                  </CardContent>
                </Grid>
              </Grid>

              <Grid item={true}>
                <CardMedia>
                  <QRCode value={SCANNER_CODES.START_RETURN} level="H" size={192} className={classes.qrCode} />
                </CardMedia>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <FulfillDialog isDialogOpen={showFulfillmentModal} setIsDialogOpen={setShowFulfillmentModal} />
      <ReturnDialog isDialogOpen={showReturnModal} setIsDialogOpen={setShowReturnModal} />
    </>
  )
}

export default Dashboard
