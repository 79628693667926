import { useEffect, useState } from 'react'

// list of keys that aren't one character and that we probably don't care about
const filterMetaCharacters = (val: string) => {
  return val
    .replace(/(Shift)|(Clear)|(CapsLock)|(NumLock)|(Alt)/g, '')
    .replace(/(Meta)|(Enter)|(Control)|(Backspace)|(Tab)/g, '')
    .replace(/(ArrowUp)|(ArrowDown)|(ArrowRight)|(ArrowLeft)|(Delete)/g, '')
    .replace(/(Escape)|(PageUp)|(PageDown)|(Insert)|(Home)|(End)/g, '')
}

const useScannerCapture = () => {
  const [scanHistory, setScanHistory] = useState<string[]>([])

  useEffect(() => {
    let scannerBuffer = ''
    let timer: NodeJS.Timeout

    const handler = (ev: KeyboardEvent) => {
      scannerBuffer += filterMetaCharacters(ev.key)

      // start recording the stream
      if (scannerBuffer.length > 0) {
        // clear the previous timer
        if (timer) {
          clearTimeout(timer)
        }
        // when this timer ends, the scanner has finished typing (hopefully)
        // record the buffer data into the scan history
        // may need to adjust the 20ms delay
        timer = setTimeout(() => {
          if (scannerBuffer.length > 3) {
            const copy = scannerBuffer.toString()
            setScanHistory((history) => history.slice(-5).concat(copy))
          }
          scannerBuffer = ''
        }, 50)

        // prevent arrow key actions from going to browser
        if (scannerBuffer.length > 3 && ev.key.includes('Arrow')) {
          ev.preventDefault()
        }
      }
    }

    document.addEventListener('keydown', handler)
    return () => {
      document.removeEventListener('keydown', handler)
      clearTimeout(timer)
    }
  }, [])

  return scanHistory
}

export default useScannerCapture
