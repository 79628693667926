import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
      position: 'sticky',
      bottom: 0,
      backgroundColor: 'white',
      boxShadow: `0px 2px 4px -1px #000`,
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
