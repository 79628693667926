import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import MUIDataTable from 'mui-datatables'

import useStyles from './table.styles'

interface IProps {
  title: string | React.ReactNode
  error: Error | { message: string }
}

const TableErrorRow = ({ error }: { error: Error | { message: string } }) => {
  const classes = useStyles()
  return (
    <div data-testid="table-error" className={classes.tableLoader}>
      <ErrorOutlineIcon />
      <p className={classes.errorMessage}>{error.message ?? 'Something went wrong. Please try again later'}</p>
    </div>
  )
}

const ErrorTable = (props: IProps) => {
  const { error, title } = props

  const tableProps = {
    title,
    columns: [
      {
        name: ' ',
        options: {
          customBodyRender: () => <TableErrorRow error={error} />,
        },
      },
    ],
    options: {
      customFooter: () => {
        return <tbody />
      },
      elevation: 1,
      filter: false,
      print: false,
      viewColumns: false,
      download: false,
      search: false,
      selectableRows: 'none' as const,
    },
    data: [['We need data here to show the row renderer']],
  }
  return <MUIDataTable {...tableProps} />
}

export default ErrorTable
