import { SnackbarProvider } from 'notistack'

import SnackbarCloseButton from '../components/snackbar-close-button'
import { AuthenticationContext, AuthProvider } from './auth'
import ThemeProvider from './theme-provider'

const AllProviders = ({ children }: { children: React.ReactNode[] | React.ReactNode }) => {
  return (
    <ThemeProvider>
      <SnackbarProvider maxSnack={3} action={(key) => <SnackbarCloseButton snackbarKey={key} />} hideIconVariant={true}>
        <AuthProvider>{children}</AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export { AllProviders, AuthenticationContext }
