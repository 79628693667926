import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AppBar, Button, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { AuthenticationContext } from '../../providers'
import Api from '../../utils/api'
import { AUTH_TOKEN, PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from '../../utils/constants'
import ScannerSettingsDialog from '../scanner-settings-dialog'
import useHeaderStyles from './header.styles'

const Header = () => {
  const classes = useHeaderStyles()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { ownUser, setIsAuthenticated } = useContext(AuthenticationContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [scannerSettingsOpen, setScannerSettingsOpen] = useState<boolean>(false)

  const handleLogout = async () => {
    Api.get('/api/logout').then((res) => {
      if (!res.ok) {
        enqueueSnackbar(res.body.detail ?? 'We could not successfully log you out', { variant: 'error' })
      } else {
        localStorage.removeItem(AUTH_TOKEN)
        setIsAuthenticated(false)
        history.push('/')
      }
    })
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Link to="/dashboard" className={classes.logo} data-testid="logo">
          <Typography variant="h6">Meshify Fulfillment</Typography>
        </Link>

        {ownUser ? (
          <>
            <Button aria-controls="header-menu" aria-haspopup="true" onClick={(e) => setAnchorEl(e.currentTarget)} className={classes.menuBtn}>
              <span className={classes.userName}>{ownUser.email ?? ownUser?.information?.first}</span>
              <ExpandMoreIcon fontSize="small" />
            </Button>

            <Menu
              id="header-menu"
              anchorEl={anchorEl}
              keepMounted={true}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <MenuItem>
                <Typography variant="body2" component="a" className={classes.menuItem} href={TERMS_OF_USE_LINK} target="_blank" rel="noreferrer">
                  Terms of Use
                </Typography>
              </MenuItem>

              <MenuItem divider={true}>
                <Typography variant="body2" component="a" className={classes.menuItem} href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
                  Privacy Policy
                </Typography>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setScannerSettingsOpen(true)
                  handleMenuClose()
                }}
                divider={true}
              >
                <Typography variant="body2" className={classes.menuItem}>
                  Scanner Settings
                </Typography>
              </MenuItem>

              <MenuItem onClick={handleLogout}>
                <Typography variant="body2" className={classes.menuItem}>
                  Log Out
                </Typography>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Button className={classes.menuBtn} onClick={handleLogout}>
            Logout
          </Button>
        )}
      </Toolbar>
      {scannerSettingsOpen && <ScannerSettingsDialog onClose={() => setScannerSettingsOpen(false)} />}
    </AppBar>
  )
}

export default Header
