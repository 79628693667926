import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { ReactNode } from 'react'
import QRCode from 'react-qr-code'

import useStyles from './index.styles'

interface IModalProps {
  show: boolean
  closeModal: () => void
  dialogTitle: ReactNode
  dialogContent?: ReactNode
  dialogActions?: ReactNode
  qrCodeAction?: string
}

const ModalDialog = (props: IModalProps) => {
  const { show, closeModal, dialogTitle, dialogContent, dialogActions, qrCodeAction } = props

  const classes = useStyles()

  return (
    <Dialog open={show} onClose={closeModal} data-testid="modal-dialog" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Grid container={true} justifyContent="space-between" alignItems="center">
          <Grid item={true}>
            <Typography variant="h6">{dialogTitle}</Typography>
          </Grid>
          <Grid item={true}>
            <IconButton aria-label="close" onClick={closeModal} size="small">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <Grid container={true} justifyContent="space-between">
        <Grid item={true} xs={true}>
          {dialogContent && <DialogContent>{dialogContent}</DialogContent>}
        </Grid>

        {qrCodeAction && (
          <Grid item={true} xs={5}>
            <DialogContent>
              <Grid container={true} justifyContent="center">
                <QRCode value={qrCodeAction} size={128} level="M" />
              </Grid>
            </DialogContent>
          </Grid>
        )}
      </Grid>

      {dialogActions && <DialogActions className={classes.dialogActions}>{dialogActions}</DialogActions>}
    </Dialog>
  )
}

export default ModalDialog