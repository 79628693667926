import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { IconButton, TableFooter, TablePagination, TableRow } from '@mui/material'

import useStyles from './index.styles'

type FooterProps = {
  count: number
  rowsPerPage: number
  changePage: (pageNum: number) => void
  currentPageNumber: number
  changeRowsPerPage: (page: string | number) => void
  onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
}

const TablePaginationActions = (props: FooterProps) => {
  const { count, rowsPerPage, onChangePage, currentPageNumber } = props

  const classes = useStyles()

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, currentPageNumber - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, currentPageNumber + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  const from = count === 0 ? 0 : currentPageNumber * rowsPerPage + 1
  const to = Math.min(count, (currentPageNumber + 1) * rowsPerPage)

  return (
    <div className={classes.wrapper}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={currentPageNumber === 0} aria-label="first page" data-testid="first-page" size="small">
        <FirstPageIcon />
      </IconButton>

      <IconButton onClick={handleBackButtonClick} disabled={currentPageNumber === 0} aria-label="previous page" data-testid="prev-page" size="small">
        <KeyboardArrowLeftIcon />
      </IconButton>

      <span className={classes.pageNum}>
        <span>{`${from}-${to} `}</span>
        <span>of</span>
        <span>{` ${count}`}</span>
      </span>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={currentPageNumber >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        data-testid="next-page"
        size="small"
      >
        <KeyboardArrowRightIcon />
      </IconButton>

      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={currentPageNumber >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        data-testid="last-page"
        size="small"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  )
}

export const ClientsideTableFooter = (props: FooterProps) => {
  return (
    <TableFooter>
      <TableRow>
        <ClientsideFooter {...props} />
      </TableRow>
    </TableFooter>
  )
}

export const ClientsideFooter = (props: FooterProps) => {
  const { count, rowsPerPage, currentPageNumber, changeRowsPerPage } = props

  const rowsPerPageOptions = [10, 20, 50]

  const handleRowChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const selectedValue = Number(event.target.value)
    changeRowsPerPage(selectedValue)
  }

  // We override functions here with empty functions because we are handling this behavior in the TablePaginationAcitons component
  return (
    <TablePagination
      page={currentPageNumber}
      rowsPerPage={rowsPerPage}
      count={count}
      rowsPerPageOptions={rowsPerPageOptions}
      onRowsPerPageChange={handleRowChange}
      onPageChange={() => null}
      labelDisplayedRows={() => null}
      ActionsComponent={() => <TablePaginationActions {...props} />}
    />
  )
}
