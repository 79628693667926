import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import Return from '.'
import Api from '../../utils/api'
import { findTwilioGateways, getAllNetworkClaims, getNodesInFolder, getReturnChannelData, getReturnNode } from './return-helper'

export enum ErrorTypes {
  NOT_FOUND,
  API_ERROR,
}

export const getChannelValue = (returnItems: IReturnItem[] | undefined) => {
  let result = ''

  if (returnItems && returnItems.length === 0) {
    result = 'No items found'
  } else if (returnItems && returnItems.length > 0) {
    result = 'Received'
  }

  return result
}

const ReturnWrapper = () => {
  const { returnId } = useParams<{ returnId: string }>()

  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  // the list of items that are being returned
  const [returnOrderItems, setReturnOrderItems] = useState<IReturnItem[]>([])
  const [allDeviceNodes, setAllDeviceNodes] = useState<INodeType[]>([]) // list of all devices that exist on a return node
  const [twilioGateways, setTwilioGateways] = useState<INodeType[]>([]) // list of all twilio gateways that need special treatment

  const [returnNodeId, setReturnNodeId] = useState<number | undefined>(undefined)
  const [returnFolderId, setReturnFolderId] = useState<number | undefined>(undefined)
  const [allNetworkClaims, setAllNetworkClaims] = useState<Partial<IClaimType>[]>([])

  const [loading, setLoading] = useState<boolean>(true)

  const updateReturnNodeStatusChannel = async (nodeId: number, value: string) => {
    const payload = {
      channelName: 'return_status',
      value,
      nodeId,
    }
    const res = await Api.post('/api/publish/cloud', payload)
    if (!res.ok) {
      enqueueSnackbar("We could not update the return node's timeline", { variant: 'info' })
    }
  }

  useEffect(() => {
    const fetchReturn = async () => {
      setLoading(true)

      // get the return node
      // this is the return node itself, the important things for now are the `id` and `folderId` of this node.
      const returnNodeResponse = await getReturnNode(returnId)
      if (!returnNodeResponse.ok) {
        enqueueSnackbar(
          returnNodeResponse.error === ErrorTypes.NOT_FOUND
            ? `Return ${returnId} was not found`
            : `Something went wrong, and we could not get return ${returnId}. Please try again later`,
          { variant: 'error' }
        )
        history.push('/dashboard')
        return
      }

      const nodeId = returnNodeResponse.body.id
      setReturnNodeId(nodeId)

      const folderId = returnNodeResponse.body.folderId

      // get the return node's channel values
      // need to grab data from the values like `return_all`, `return_items`
      const returnNodeChannelsResponse = await getReturnChannelData(nodeId)
      if (!returnNodeResponse.ok) {
        enqueueSnackbar('Something went wrong, and the return node information did not load. Please try again', { variant: 'error' })
        history.push('/dashboard')
        return
      }

      const returnNodesChannels = returnNodeChannelsResponse.body

      // get the nodes (specifically the devices) in the return node's folder.
      // we need to compare the value scanned to the nodes in this folder and remove the nodes.
      const deviceNodes: INodeType[] = await getNodesInFolder(folderId)
      if (!deviceNodes?.length) {
        enqueueSnackbar("That return's parent folder does not have any nodes", { variant: 'error' })
        history.push('/dashboard')
        return
      }
      setAllDeviceNodes(deviceNodes)

      // fetch all the claims for deletion later
      const allClaims = await getAllNetworkClaims()
      if (!allClaims || allClaims.length === 0) {
        enqueueSnackbar('Failed to grab all network claims. Please try again', { variant: 'error' })
        history.push('/dashboard')
        return
      }
      setAllNetworkClaims(allClaims)

      let returnItemsArr = returnNodesChannels.return_items.value ?? []
      if (typeof returnItemsArr === 'string') {
        try {
          returnItemsArr = JSON.parse(returnItemsArr)
        } catch (e) {
          returnItemsArr = []
        }
      }

      const cleanedReturnedItems = returnItemsArr.map((uniqueId: string) => uniqueId.replaceAll('-', '').toLowerCase())
      let returnAllValue = returnNodesChannels.return_all.value ?? false
      if (typeof returnAllValue === 'string') {
        try {
          returnAllValue = JSON.parse(returnAllValue)
        } catch (e) {
          returnAllValue = false
        }
      }

      // compare returnItems to deviceNodes without dashes and lowercase
      // items that exist in both arrays are our actual return items that will be rendered as cards
      // if `return_all` is true, we want to return every single device
      const existInBothArrays: INodeType[] = deviceNodes.filter((el) => {
        return returnAllValue || cleanedReturnedItems.includes(el.uniqueId.replaceAll('-', '').toLowerCase())
      })

      const itemsToReturn: IReturnItem[] = existInBothArrays.map((item) => ({ uniqueId: item.uniqueId, title: item.vanity, scanned: false, id: item.id }))

      setReturnOrderItems(itemsToReturn)
      setReturnFolderId(folderId)

      // after we're done loading everything, update the return node return_status channel
      updateReturnNodeStatusChannel(nodeId, getChannelValue(itemsToReturn))

      // find twilio gateways
      try {
        const gateways = deviceNodes.filter((device) => device.nodeTypeName === 'gateway')
        const twilios = await findTwilioGateways(gateways)
        setTwilioGateways(twilios)
      } catch (e) {
        enqueueSnackbar((e as any).message, { variant: 'error' })
        history.push('/dashboard')
        return
      }
      setLoading(false)
    }

    fetchReturn()
  }, [returnId])

  return (
    <Return
      returnId={returnId}
      returnOrderItems={returnOrderItems}
      returnFolderId={returnFolderId}
      isLoading={loading}
      deviceNodes={allDeviceNodes}
      twilioGateways={twilioGateways}
      returnNodeId={returnNodeId}
      allNetworkClaims={allNetworkClaims}
    />
  )
}

export default ReturnWrapper
