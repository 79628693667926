import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import classNames from 'classnames'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { useHistory } from 'react-router-dom'

import ModalDialog from '../../components/modal-dialog'
import OrderItemCard from '../../components/order-item-card'
import Api from '../../utils/api'
import { SCANNER_CODES } from '../../utils/constants'
import useScannerCapture from '../../utils/custom-hooks/use-scanner-capture'
import { parseScannedOrderItem, playErrorSound, playSuccessSound, playTwilioSound } from '../../utils/helper-functions'
import ConfirmReturnFinishedDialog from './confirm-finish-dialog'
import EmptyReturnView from './empty-return-view'
import useStyles from './index.styles'
import { deleteClaimByUniqueId } from './return-helper'

export enum ScanErrorTypes {
  NOT_FOUND = 'Looks like you tried to scan an item that is not part of the return order',
  DUPLICATE = 'It seems you have already scanned that item',
  WRONG_BARCODE = 'This might be an invalid barcode. Please try again',
  UNKNOWN_SCAN = 'Did not recognize that scan. Please try again',
}

interface IProps {
  isLoading: boolean
  returnId: string // id of a return order
  returnNodeId: number | undefined // id of a return node
  returnOrderItems: IReturnItem[]
  returnFolderId: number | undefined
  deviceNodes: INodeType[]
  twilioGateways: INodeType[]
  allNetworkClaims: Partial<IClaimType>[]
}

type ReturnItemSortFunction = (a: IReturnItem, b: IReturnItem) => number

// unique Ids are considered the same in variaty of cases
export const areUniqueIdsEqual = (uniqueId1: string, uniqueId2: string): boolean => {
  return (
    uniqueId1.toLowerCase() === uniqueId2 ||
    // insert dashes every two characters
    uniqueId1.toLowerCase() === uniqueId2.match(/.{1,2}/g)?.join('-') ||
    // chirpstackgatewayuid format, insert ‘fffe’ in the middle
    uniqueId1.toLowerCase() === uniqueId2.substring(0, uniqueId2.length / 2) + 'fffe' + uniqueId2.substring(uniqueId2.length / 2) ||
    // insert ‘200’ at the beginning
    uniqueId1.toLowerCase() === `200${uniqueId2}`
  )
}

const Return = (props: IProps) => {
  const { returnId, returnOrderItems, isLoading, deviceNodes, twilioGateways, returnNodeId, allNetworkClaims } = props
  const classes = useStyles()
  const scannerHistory = useScannerCapture()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const [returnOrder, setReturnOrder] = useState<IReturnItem[]>([])
  const [showFinishReturnDialog, setShowFinishReturnDialog] = useState<boolean>(false)
  const [scanError, setScanError] = useState<string | undefined>(undefined)

  // states to keep track of current sort from the Select and the sort function that belongs with it
  const [sortDropdownValue, setSortDropdownValue] = useState<SortOptions>('')
  const [sortFunction, setSortFunction] = useState<ReturnItemSortFunction | undefined>(undefined)

  // the text in the 'search for an item' text box
  const [searchText, setSearchText] = useState<string>('')

  const totalItems = returnOrder.length
  const scannedItems = returnOrder.filter((item) => item.scanned).length
  // if the letters `rti` appear at the very end or one character from the end, it is an RTI order
  const isRTI = returnId?.slice(-3).toLowerCase() === 'rti' || returnId?.slice(-4, -1).toLowerCase() === 'rti'
  // if there is a twilio gateway, show banner
  const hasTwilio = twilioGateways?.length > 0

  useEffect(() => {
    if (returnOrder.length > 0 || returnOrderItems.length === 0) {
      return
    }

    setReturnOrder(returnOrderItems)
  }, [returnOrderItems])

  useEffect(() => {
    const command = scannerHistory.slice(-1).pop() ?? ''
    if (!command) {
      return
    }

    // handle global commands first, then check for a scanned item in the else
    if (command === SCANNER_CODES.COMMAND_CONFIRM) {
      setScanError(undefined)
      return
      // if Finish Early barcode was scanned
    } else if (command === SCANNER_CODES.FINISH_RETURN) {
      setShowFinishReturnDialog(true)
      return
    } else {
      const scannedBarcode = parseScannedOrderItem(command)
      if (scannedBarcode) {
        scanItem(scannedBarcode)
      } else {
        playErrorSound()
        setScanError(ScanErrorTypes.UNKNOWN_SCAN)
      }
    }
  }, [scannerHistory])

  useEffect(() => {
    const scannedSortFunction = (a: IReturnItem, b: IReturnItem) => Number(b.scanned) - Number(a.scanned)
    const unscannedSortFunction = (a: IReturnItem, b: IReturnItem) => scannedSortFunction(a, b) * -1
    const nameAscendingSortFunction = (a: IReturnItem, b: IReturnItem) => a.title.localeCompare(b.title)
    const nameDescendingSortFunction = (a: IReturnItem, b: IReturnItem) => nameAscendingSortFunction(a, b) * -1

    if (sortDropdownValue === '') {
      setSortFunction(undefined)
    } else if (sortDropdownValue === 'scanned') {
      setSortFunction(() => scannedSortFunction)
    } else if (sortDropdownValue === 'not scanned') {
      setSortFunction(() => unscannedSortFunction)
    } else if (sortDropdownValue === 'title ascending') {
      setSortFunction(() => nameAscendingSortFunction)
    } else if (sortDropdownValue === 'title descending') {
      setSortFunction(() => nameDescendingSortFunction)
    }
  }, [sortDropdownValue])

  // if all items were scanned, send user back to dashboard with a success message and update return_status and timeline channel values
  useEffect(() => {
    if (totalItems > 0 && scannedItems > 0 && totalItems === scannedItems) {
      enqueueSnackbar('Return was completed successfully', { variant: 'success' })

      setTimeout(() => {
        history.push('/dashboard')
      }, 3000)

      const updateChannelValues = async () => {
        const timelineRes = await Api.post('/api/publish/cloud', {
          channelName: 'timeline',
          value: 'Return finished',
          nodeId: returnNodeId,
        })
        if (!timelineRes.ok) {
          enqueueSnackbar('We could not update the timeline channel of this node', { variant: 'info' })
        }

        const returnStatusRes = await Api.post('/api/publish/cloud', {
          channelName: 'return_status',
          value: 'Received in Full',
          nodeId: returnNodeId,
        })
        if (!returnStatusRes.ok) {
          enqueueSnackbar('We could not update the return_status channel of this node', { variant: 'info' })
        }
      }

      updateChannelValues()
    }
  }, [totalItems, scannedItems])

  // if you scan “1234”, you should try to match uniqueId with 12-34, 2001234, 12fffe34, and 1234
  const scanItem = (scannedBarcode: BarcodeObject) => {
    const uniqueId = ('imei' in scannedBarcode ? scannedBarcode.imei : scannedBarcode.sn)?.toLowerCase()
    const isAlreadyScanned = returnOrder.find((item) => item.uniqueId && uniqueId && areUniqueIdsEqual(item.uniqueId, uniqueId) && item.scanned)
    const unscannedItemsFound = returnOrder.find((item) => item.uniqueId && uniqueId && areUniqueIdsEqual(item.uniqueId, uniqueId) && !item.scanned)

    // if uniqueId is empty, it probably means it's invalid or it's not a device.
    if (!uniqueId) {
      playErrorSound()
      setScanError(ScanErrorTypes.WRONG_BARCODE)
      return
    }

    // if item was already scanned
    if (isAlreadyScanned) {
      playErrorSound()
      setScanError(ScanErrorTypes.DUPLICATE)
      return
    }

    // if we can't find the item - return not found
    if (!unscannedItemsFound) {
      playErrorSound()
      setScanError(ScanErrorTypes.NOT_FOUND)
      return
    }

    if (unscannedItemsFound) {
      markAsScannedAndCleanUp(unscannedItemsFound, scannedBarcode.type)
    }
  }

  // mark the item as a successful scan
  const markAsScannedAndCleanUp = async (returnItem: IReturnItem, barcodeType: BarcodeObject['type']) => {
    const deletedBoth = await deleteNodeAndClaim(returnItem, barcodeType)

    if (barcodeType === 'leakAndFreeze') {
      await deleteSignupKey(returnItem)
    }

    if (!deletedBoth) {
      playErrorSound()
      enqueueSnackbar('Failed to delete the node or claim, try again', { variant: 'error' })
    } else {
      const isTwilioGateway = twilioGateways.find((device) => areUniqueIdsEqual(device.uniqueId, returnItem.uniqueId))
      isTwilioGateway ? playTwilioSound() : playSuccessSound()
      // mark item as scanned in the UI
      setReturnOrder((previousOrder) =>
        previousOrder.map((item: IReturnItem) => {
          const newItem = { ...item }
          if (newItem.id === returnItem.id) {
            newItem.scanned = true
          }

          return newItem
        })
      )

      return true
    }
  }

  // Before the item can be marked as scanned, we need to delete the node and claim
  const deleteNodeAndClaim = async (returnItem: IReturnItem, barcodeType: BarcodeObject['type']) => {
    const device = deviceNodes.find((node) => node.id === returnItem.id)

    // nodes cannot be deleted if they're attached to alias channels, we should find and delete them
    // determine if return node is an nbiot_leak or gateway device. If true, find alias channels and delete them, if any
    const shouldDeleteAliasChannels = device?.nodeTypeName === 'gateway' || device?.nodeTypeName === 'nbiot_leak'
    if (shouldDeleteAliasChannels) {
      const aliasChannelsRes = await Api.post('/api/aliaschannels/query', {
        filters: [
          { fieldName: 'sourceNodeId', operator: 'eq', value: device.id },
          { fieldName: 'aliasNodeId', operator: 'eq', value: device.id },
        ],
        type: 'or',
      })
      if (!aliasChannelsRes.ok) {
        enqueueSnackbar('We could not retreive alias channels', { variant: 'info' })
      } else {
        const aliasChannels = aliasChannelsRes.body.results
        if (aliasChannels.length > 0) {
          await Promise.all(
            aliasChannels.map(async (aliasChannel: any) => {
              const deleteRes = await Api.del(`/api/aliaschannels/${aliasChannel.id}`)
              if (!deleteRes.ok) {
                enqueueSnackbar('We could not delete alias channel', { variant: 'info' })
              }
            })
          )
        }
      }
    }

    let wasNodeDeleted = false
    try {
      const node = deviceNodes.find((d) => d.id === returnItem.id)
      if (node) {
        // this node is about to get immediately deleted but it would be nice for aurora purposes
        // if there was an identifier to know that this item was returned and when
        await Api.put(`/api/nodes/${returnItem.id}`, {
          ...node,
          metadata: {
            ...node.metadata,
            returned: new Date().valueOf() / 1000,
          },
        })
      }
      const deletedNode = await Api.del(`/api/nodes/${returnItem.id}`)
      if (deletedNode.ok || deletedNode.status === 404) {
        wasNodeDeleted = true
      }
    } catch {
      /* just warn user of a problem */
    }

    let wasClaimDeleted = false
    const deletedClaim = await deleteClaimByUniqueId(returnItem.uniqueId, barcodeType, allNetworkClaims)
    if (deletedClaim.ok) {
      wasClaimDeleted = true
    }

    return wasNodeDeleted && wasClaimDeleted
  }

  const deleteSignupKey = async (returnItem: IReturnItem) => {
    // this endpoint currently does one and only one thing which is deleting signup keys.
    // possibly in the future the backend will support deleting nodes/claims or whatever and we can refactor
    try {
      await Api.post('/api/v2/fulfillment/rma', {
        sku: 'SC-LM-1', // only leak and freeze devices, in the future we need to replace this with the barcode sku
        imei: returnItem.uniqueId,
      })
    } catch {
      // do nothing?
    }
  }

  return (
    <>
      <Grid container={true} spacing={4} data-cy="returnOrderPage">
        <Grid item={true} xs={12}>
          <Typography variant="h4" data-cy={`returnOrder${returnId}`}>
            Return Order #{returnId}
          </Typography>
        </Grid>

        {isRTI && (
          <Grid item={true} xs={12}>
            <Alert severity="info" variant="filled">
              <AlertTitle>This is a Return to Inventory order (RTI)</AlertTitle>
              Please return the items that are successfully scanned back into sellable inventory for fulfillment.
            </Alert>
          </Grid>
        )}

        {hasTwilio && (
          <Grid item={true} xs={12}>
            <Alert severity="warning" variant="standard">
              <AlertTitle>Twilio gateway detected</AlertTitle>
              Please set aside the Twilio gateways into a separate area. The Twilio gateways are marked in red.
            </Alert>
          </Grid>
        )}

        {isLoading && (
          <Grid item={true} xs={12} container={true} justifyContent="center">
            <CircularProgress color="secondary" data-testid="spinner" data-cy="loadingSpinner" />
          </Grid>
        )}

        {!isLoading && returnOrder.length > 0 && (
          <>
            <Grid item={true} xs={12}>
              <Paper className={classes.orderWrapper}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={9} sm={8} md={9} lg={10} xl={10}>
                    <Typography variant="subtitle2">Return list ({totalItems} items total)</Typography>
                  </Grid>

                  <Grid item={true} xs={3} sm={4} md={3} lg={2} xl={2}>
                    <Typography variant="caption">return order progress:</Typography>
                    <LinearProgress variant="determinate" value={(scannedItems / totalItems) * 100} />
                  </Grid>

                  <Grid item={true} xs={9} sm={8} md={9} lg={10} xl={10}>
                    <TextField
                      variant="outlined"
                      placeholder="Search for an item"
                      size="small"
                      value={searchText}
                      onChange={(event) => setSearchText(event.target.value)}
                      inputProps={{
                        'data-testid': 'filter-textfield',
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: searchText ? (
                          <InputAdornment position="end">
                            <IconButton size="small" onClick={() => setSearchText('')} data-testid="clear-search-button">
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ) : undefined,
                      }}
                    />
                  </Grid>

                  <Grid item={true} xs={3} sm={4} md={3} lg={2} xl={2}>
                    <FormControl variant="outlined" fullWidth={true} size="small">
                      <InputLabel id="sort-by-label">Sort by</InputLabel>
                      <Select
                        labelId="sort-by-label"
                        data-testid="sort-by-select"
                        value={sortDropdownValue}
                        onChange={(event) => {
                          setSortDropdownValue(event.target.value as SortOptions)
                        }}
                        variant="outlined"
                        label="Sort by"
                      >
                        <MenuItem value="">unsorted</MenuItem>
                        <MenuItem value="scanned">scanned first</MenuItem>
                        <MenuItem value="not scanned">unscanned first</MenuItem>
                        <MenuItem value="title ascending">name ascending</MenuItem>
                        <MenuItem value="title descending">name descending</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <Grid container={true} spacing={2}>
                      {returnOrder
                        .filter((item: IReturnItem) => item.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
                        .sort(sortFunction)
                        .map((item) => (
                          <Grid item={true} xs={12} sm={6} md={4} lg={3} xl={3} key={item.id}>
                            <OrderItemCard item={item} isTwilioGateway={!!twilioGateways.find((device) => areUniqueIdsEqual(device.uniqueId, item.uniqueId))} />
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item={true} xs={12}>
              <Typography variant="subtitle2" component="div" className={classes.scannerLabel}>
                Scan this to finish order any time OR click the button:
              </Typography>

              <QRCode
                value={SCANNER_CODES.FINISH_RETURN}
                size={128}
                level="M"
                data-testid="finish-return-qr-code"
                className={classNames(classes.doneQRcode, { [classes.dimmedQRcode]: showFinishReturnDialog })}
              />

              <Button className={classes.finishReturnButton} onClick={() => setShowFinishReturnDialog(true)}>
                Finish return now
              </Button>
            </Grid>
          </>
        )}

        {!isLoading && returnOrder.length === 0 && <EmptyReturnView />}
      </Grid>

      <ConfirmReturnFinishedDialog
        isDialogOpen={showFinishReturnDialog}
        setIsDialogOpen={setShowFinishReturnDialog}
        returnOrderItems={returnOrder}
        deviceNodes={deviceNodes}
        returnNodeId={Number(returnNodeId)}
      />

      <ModalDialog
        show={scanError !== undefined}
        dialogTitle="Scan Error"
        dialogContent={scanError}
        qrCodeAction={SCANNER_CODES.COMMAND_CONFIRM}
        closeModal={() => setScanError(undefined)}
        dialogActions={<Button onClick={() => setScanError(undefined)}>close</Button>}
      />
    </>
  )
}

export default Return
