import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    forgotBtn: {
      margin: '15px 0',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[800],
    },
    dialogTitle: {
      textAlign: 'center',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
