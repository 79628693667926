import { Alert, Button, Grid, Typography } from '@mui/material'
import { useEffect } from 'react'
import QRCode from 'react-qr-code'
import { useHistory } from 'react-router-dom'

import { SCANNER_CODES } from '../../../utils/constants'
import useScannerCapture from '../../../utils/custom-hooks/use-scanner-capture'
import useStyles from './index.styles'

const EmptyReturnView = () => {
  const classes = useStyles()
  const history = useHistory()
  const scannerHistory = useScannerCapture()

  // go to Dashboard if Go to Dashboard QR code was scanned
  useEffect(() => {
    const command = scannerHistory.slice(-1).pop()
    if (command === SCANNER_CODES.COMMAND_ROUTE_HOME) {
      history.push('/dashboard')
    }
  }, [scannerHistory])

  return (
    <Grid container={true} justifyContent="center" spacing={4} className={classes.container}>
      <Grid item={true} xs={8}>
        <Alert severity="error">This return order does not appear to have any items you can scan. If you think this is a mistake, please contact support</Alert>
      </Grid>

      <Grid item={true} xs={12}>
        <Typography variant="body2">To go back to Dashboard, click the button or scan the QR code below</Typography>
      </Grid>

      <Grid item={true} xs={12}>
        <QRCode value={SCANNER_CODES.COMMAND_ROUTE_HOME} level="M" size={192} data-testid="go-to-dashboard-qr" />
      </Grid>

      <Grid item={true} xs={12}>
        <Button onClick={() => history.push('/dashboard')}>Go back to Dashboard</Button>
      </Grid>
    </Grid>
  )
}

export default EmptyReturnView
