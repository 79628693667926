import ClearIcon from '@mui/icons-material/Clear'
import { Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useHistory } from 'react-router'

import ModalDialog from '../../../components/modal-dialog'

interface IProps {
  isDialogOpen: boolean
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>
}

const ReturnDialog = (props: IProps) => {
  const { isDialogOpen, setIsDialogOpen } = props
  const history = useHistory()

  const [returnOrder, setReturnOrder] = useState<string>('')

  const handleContinueToReturn = useCallback(() => {
    history.push(`/return/${returnOrder}`)
  }, [returnOrder])

  return (
    <ModalDialog
      show={isDialogOpen}
      dialogContent={
        <>
          <Typography variant="body1" paragraph={true}>
            Scan or enter the order number you would like to return
          </Typography>

          <TextField
            fullWidth={true}
            variant="outlined"
            label="Order Number"
            size="small"
            autoFocus={true}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setReturnOrder(e.target.value)
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                handleContinueToReturn()
              }
            }}
            value={returnOrder}
            inputProps={{
              'aria-label': 'return-textfield',
            }}
            InputProps={{
              endAdornment: returnOrder ? (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setReturnOrder('')} data-testid="clear-search-button">
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : undefined,
            }}
          />
        </>
      }
      dialogActions={
        <Button variant="contained" color="primary" onClick={handleContinueToReturn} disabled={!returnOrder}>
          Continue to return
        </Button>
      }
      dialogTitle="Return"
      closeModal={() => {
        setIsDialogOpen(false)
      }}
    />
  )
}

export default ReturnDialog
