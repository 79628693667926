import { Button, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import ModalDialog from '../../../components/modal-dialog'
import Api from '../../../utils/api'
import { SCANNER_CODES } from '../../../utils/constants'
import useScannerCapture from '../../../utils/custom-hooks/use-scanner-capture'

interface IProps {
  isDialogOpen: boolean
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>
  returnOrderItems: IReturnItem[]
  deviceNodes: INodeType[]
  returnNodeId: number
}

interface IMissingItem {
  sku: string
  sn: string
  iccid?: string
  model: string
}

const ConfirmReturnFinishedDialog = (props: IProps) => {
  const { isDialogOpen, setIsDialogOpen, returnOrderItems, deviceNodes, returnNodeId } = props

  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const scannerHistory = useScannerCapture()

  useEffect(() => {
    const command = scannerHistory.slice(-1).pop() ?? ''
    if (!command) {
      return
    }

    if (command === SCANNER_CODES.FINISH_RETURN_CONFIRM) {
      finishReturn()
    }
  }, [scannerHistory])

  const finishReturn = async () => {
    setIsDialogOpen(false)

    const missingItems = generateMissingItems()

    const payload = {
      channelName: 'missing_items',
      value: JSON.stringify(missingItems),
      nodeId: returnNodeId,
    }

    const res = await Api.post('/api/publish/cloud', payload)
    if (!res.ok) {
      enqueueSnackbar('We could not complete the retrun with some of the items not scanned', { variant: 'error' })
    } else {
      enqueueSnackbar('Return was completed successfully', { variant: 'success' })
      history.push('/dashboard')
    }

    const timelineRes = await Api.post('/api/publish/cloud', {
      channelName: 'timeline',
      value: 'Return finished manually',
      nodeId: returnNodeId,
    })
    if (!timelineRes.ok) {
      enqueueSnackbar('We could not update the timeline channel of this node', { variant: 'info' })
    }

    const returnStatusRes = await Api.post('/api/publish/cloud', {
      channelName: 'return_status',
      value: 'Discrepant',
      nodeId: returnNodeId,
    })
    if (!returnStatusRes.ok) {
      enqueueSnackbar('We could not update the return_status channel of this node', { variant: 'info' })
    }
  }

  const generateMissingItems = () => {
    const notScannedReturnCards = returnOrderItems.filter((item) => item.scanned === false)

    // list of nodes that weren't scanned
    const notScannedDevices: INodeType[] = []
    deviceNodes.forEach((node) => {
      notScannedReturnCards.forEach((returnItem) => {
        if (node.id === returnItem.id) {
          notScannedDevices.push(node)
        }
      })
    })

    // all devices that weren't scanned need to be added to missing_items channel of a return node
    // with a payload specific for each device type
    const missingItems: IMissingItem[] = []
    notScannedDevices.forEach((node) => {
      let missingReturnItem = {}

      // for getaways
      if (node.nodeTypeName === 'gateway') {
        missingReturnItem = {
          sku: 'TK-KONA-WT1',
          sn: node.uniqueId.substr(3),
          iccid: node?.metadata?.iccid ?? '',
          model: node?.metadata?.model ?? '',
        }
      }
      // for sensors
      else {
        let sku = node.nodeTypeName
        if (node.nodeTypeName?.includes('leak')) {
          sku = 'MY-LD1'
        } else if (node.nodeTypeName?.includes('motion')) {
          sku = 'MY-MD1'
        } else if (node.nodeTypeName?.includes('probe')) {
          sku = 'MY-TD1'
        }

        missingReturnItem = {
          sku,
          sn: node.uniqueId.replaceAll('-', ''),
          model: node?.metadata?.functionName + '-' + node?.metadata?.hardwareVersion + '-' + node?.metadata?.functionVersion,
        }
      }

      missingItems.push(missingReturnItem as IMissingItem)
    })

    return missingItems
  }

  return (
    <ModalDialog
      show={isDialogOpen}
      qrCodeAction={SCANNER_CODES.FINISH_RETURN_CONFIRM}
      dialogContent={
        <Typography variant="body1" paragraph={true}>
          Are you sure you would like to finish the return order? Scan the code or click the button below to confirm
        </Typography>
      }
      dialogActions={
        <Button variant="contained" color="primary" onClick={finishReturn}>
          Finish the return
        </Button>
      }
      dialogTitle="Complete return"
      closeModal={() => {
        setIsDialogOpen(false)
      }}
    />
  )
}

export default ConfirmReturnFinishedDialog
