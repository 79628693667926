import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = () =>
  createStyles({
    tableLoader: {
      textAlign: 'center',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    errorMessage: {
      paddingLeft: '10px',
      margin: 0,
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
