import { Alert, AlertTitle, CircularProgress, Typography } from '@mui/material'
import { useEffect } from 'react'

import logger from '../../utils/logger'

interface ContactInformationProps {
  nodeId: number
  loading: boolean
  contact: UserType | undefined
  addressCode: string | undefined
  nbiotOnOrder: boolean
  folderId: number | undefined
}

const ContactInformation = ({ nodeId, loading, contact, addressCode, nbiotOnOrder, folderId }: ContactInformationProps) => {
  const error =
    !addressCode && !contact
      ? // if there is no contact or address code
        `No location manager or address code found on folder ${folderId}`
      : !addressCode
      ? // if there is no address code
        `No address code found on folder ${folderId}`
      : !contact
      ? // if there is no contact
        `No location manager found on folder ${folderId}`
      : // otherwise no error
        ''

  useEffect(() => {
    if (loading) return

    if (error) {
      logger.log(nodeId, 'Contact Information Error', {
        nbiotOnOrder,
        error,
      })
    }
  }, [loading, error])

  if (loading) {
    return <CircularProgress />
  }

  if (nbiotOnOrder && (!addressCode || !contact)) {
    return (
      <Alert variant="standard" severity="error">
        <AlertTitle>This order cannot be completed. Please correct the errors before continuing.</AlertTitle>
        {error}
      </Alert>
    )
  } else {
    return (
      <>
        {contact && (
          <Typography variant="h6" component="span">
            {contact?.information?.first} {contact?.information?.last}
          </Typography>
        )}
        {addressCode && (
          <Typography variant="subtitle2" component="span" data-cy="addressCode">
            {contact && ', '}
            address code - {addressCode}
          </Typography>
        )}
      </>
    )
  }
}

export default ContactInformation
