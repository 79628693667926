import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'

export const styles = (theme: Theme) =>
  createStyles({
    unknownPaper: {
      padding: theme.spacing(2),
    },
    devicePaper: {
      display: 'flex',
      marginBlock: theme.spacing(2),
    },
    deviceImgBox: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(2),
      alignItems: 'center',
      justifyContent: 'center',
    },
    duplicateScanText: {
      padding: theme.spacing(0, 1, 2, 1),
    },
    gridItem: {
      padding: theme.spacing(2, 2, 2, 4),
    },
    labelKeyItem: {
      minWidth: '100px',
    },
    flex: { display: 'flex' },
  })

const useStyles = makeStyles(styles)
export default useStyles
