import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    orderWrapper: {
      padding: theme.spacing(2),
    },
    spinner: {
      display: 'block',
      margin: '0 auto',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
