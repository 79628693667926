import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import {
  Alert,
  AlertColor,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import BulkItemModal from '../../components/bulk-item-modal'
import ModalDialog from '../../components/modal-dialog'
import OrderItemCard from '../../components/order-item-card'
import Api from '../../utils/api'
import { BULK_ITEM_REMEMBER, FAILED_SIGNUP_KEY, SCANNER_CODES, SCROLL_ON_SCAN, SORT_ITEMS } from '../../utils/constants'
import useScannerCapture from '../../utils/custom-hooks/use-scanner-capture'
import { parseScannedOrderItem, playErrorSound, playSuccessSound } from '../../utils/helper-functions'
import logger from '../../utils/logger'
import ContactInformation from './contact-information'
import useStyles from './index.styles'
import { getDevicesAccount, getDevicesFolderId, getDevicesOrder, setFulfillmentOrderDone } from './order-helper'

export enum ErrorTypes {
  NOT_FOUND,
  API_ERROR,
  ALREADY_FULFILLED,
}

export enum ScanErrorTypes {
  NOT_FOUND = 'Looks like you tried to scan an item that is not part of the order',
  DUPLICATE = 'It seems you have already scanned that item',
  FILLED_ALLOTED_AMOUNT = 'You have already scanned enough of that item',
  UNKNOWN_SCAN = 'Did not recognize that scan. Please try again',
}

type OrderItemSortFunction = (a: IOrderItem, b: IOrderItem) => number

interface IProps {
  orderId: string
  nodeId: number
  initialOrder: IOrderItem[]
  loading: boolean
  contactLoading: boolean
  error: ErrorTypes | undefined
  shortOrderNumber: number
  folderId: number | undefined
  addressCode: string | undefined
  locationManager: IUserType | undefined
  setDeviceHasBeenScanned: Dispatch<SetStateAction<boolean>>
}

interface BannerProps {
  message: string
  severity: AlertColor
  show: boolean
}

interface BulkItemPromptProps {
  title: string
  sku: string
  show: boolean
  remaining: number
}

const Order = (props: IProps) => {
  const { orderId, nodeId, initialOrder, loading, contactLoading, error, shortOrderNumber, folderId, addressCode, locationManager, setDeviceHasBeenScanned } =
    props

  const scannerHistory = useScannerCapture()
  const classes = useStyles()
  const history = useHistory()

  const [order, setOrder] = useState<IOrderItem[]>(initialOrder)
  const [loadingItem, setLoadingItem] = useState<number | undefined>(undefined)
  const processingRef = useRef<boolean>(false)
  const [scanError, setScanError] = useState<ScanErrorTypes | string | undefined>()
  const [showBulkItemPrompt, setShowBulkItemPrompt] = useState<BulkItemPromptProps>({ title: '', sku: '', show: false, remaining: -1 })
  const bulkItemResolve = useRef<BulkItemResolverFunctionType | undefined>()

  // states to keep track of current sort from the Select and the sort function that belongs with it
  const [sortDropdownValue, setSortDropdownValue] = useState<SortOptions>((localStorage.getItem(SORT_ITEMS) as SortOptions) || '')
  const [sortFunction, setSortFunction] = useState<OrderItemSortFunction | undefined>(undefined)
  const [scrollOnScan, setScrollOnScan] = useState<boolean>(localStorage.getItem(SCROLL_ON_SCAN) === 'true')

  // the text in the 'search for an item' text box
  const [searchText, setSearchText] = useState<string>('')

  const [banner, setBanner] = useState<BannerProps>({ message: '', severity: 'info', show: false })

  const totalItems = order.length
  const scannedItems = order.filter((item: IOrderItem) => item.scanned).length
  const nbiotOnOrder = !!order.find((item: IOrderItem) => item.sku === 'SC-LM-1')

  useEffect(() => {
    if (order.length > 0 || initialOrder.length === 0) {
      return
    }

    setOrder([...initialOrder])
  }, [initialOrder])

  useEffect(() => {
    const handleCommand = async () => {
      const command = scannerHistory.slice(-1).pop() ?? ''
      if (!command) {
        return
      }

      logger.log(nodeId, 'Raw scanner input', command)

      const scannedBarcode = parseScannedOrderItem(command)
      // handle global commands first, then check for a scanned item in the else
      if (command === SCANNER_CODES.COMMAND_CONFIRM) {
        setScanError(undefined)
      } else if (showBulkItemPrompt.show || scanError !== undefined) {
        // if there is a modal available, don't allow any other scans.
        // if a new modal or a new barcode shows up during development, it must be handled before this if condition
        playErrorSound()
      } else if (processingRef.current) {
        logger.log(nodeId, 'Scan too fast?', { command })
      } else if (scannedBarcode && !processingRef.current) {
        processingRef.current = true
        const { itemFound, needsActivation } = (await findScannedItem(scannedBarcode)) ?? {}
        if (itemFound) {
          if (scrollOnScan) {
            document.querySelector(`#order-item-card-${itemFound.id}`)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }

          let activationFailed = false
          if (scannedBarcode.type !== 'generic' && needsActivation) {
            setLoadingItem(itemFound.id)
            const request = {
              folderId,
              shortOrderNumber,
              sku: scannedBarcode.sku,
              model: scannedBarcode.model,
              euid: 'imei' in scannedBarcode ? scannedBarcode.imei : scannedBarcode.sn,
              iccid: 'iccid' in scannedBarcode ? scannedBarcode.iccid : undefined,
              imei: 'imei' in scannedBarcode ? scannedBarcode.imei : undefined,
              serialNumber: scannedBarcode.sn,
              deviceOwnership: itemFound.device_ownership,
            }
            try {
              const response = await Api.post('/api/v2/fulfillment/activate', request)

              if (!response.ok) {
                throw response.body
              }

              setDeviceHasBeenScanned(true)
            } catch (e: any) {
              activationFailed = true
              setScanError(
                `There was an issue with the activation on this device. Please try scanning it again. If it continues to occur, try using another device and place this one aside. ${
                  e?.error || e?.message
                }`
              )
              playErrorSound()
              logger.log(nodeId, 'Activation failed', {
                request,
                error: { e: JSON.stringify(e), error: e?.error, message: e?.message },
                scannedBarcode,
                itemFound,
              })
            } finally {
              setLoadingItem(undefined)
            }
          }

          if (!activationFailed) {
            let scanAll = false
            if (scannedBarcode.type === 'leakAndFreeze' && scannedBarcode.imei) {
              await assignSignupKeyToDevice(itemFound, scannedBarcode.imei)
            } else if (scannedBarcode.type === 'generic') {
              // prompt the user to see if they want to scan all these devices
              const items = order.filter((item) => item.sku === scannedBarcode.sku && !item.scanned)
              if (items.length > 1) {
                scanAll = await handleBulkItemPrompt(items[0].title, scannedBarcode.sku, items.length)
              }
            }

            markItemAsScanned(itemFound, 'imei' in scannedBarcode ? scannedBarcode.imei : scannedBarcode.sn, scanAll)
            playSuccessSound()
            logger.log(nodeId, 'Successful scan', { command, itemFound, scannedBarcode, scanAll })
          }
        } else {
          logger.log(nodeId, 'Item not found', { scannedBarcode })
          playErrorSound()
        }
        processingRef.current = false
      } else {
        playErrorSound()
        setScanError(ScanErrorTypes.UNKNOWN_SCAN)
      }
    }

    handleCommand()
  }, [scannerHistory])

  useEffect(() => {
    // we will be checking if all items are scanned in this useEffect
    // if an unscanned item is found, return early to abort
    const unscanned = order.find((item) => item.scanned === false)
    if (unscanned) {
      return
    }

    // make sure there is at least one item scanned
    if (totalItems !== scannedItems || scannedItems === 0 || totalItems === 0) {
      return
    }

    const actions = async () => {
      // set this boolean back to false to disable the "are you sure?" prompt
      setDeviceHasBeenScanned(false)

      const hashIds = order.map((item) => item.signupKey).filter((x) => x) || []

      const response = await setFulfillmentOrderDone(nodeId, hashIds as string[], locationManager?.id || 0, addressCode || '')
      // scroll to the top of page so the banner is shown
      window.scrollTo({ behavior: 'smooth', top: 0 })

      if (response.ok) {
        setBanner({ message: 'The order was successfully fulfilled!', severity: 'success', show: true })
        await logger.log(nodeId, 'Order completed', {})
        setTimeout(() => {
          history.push('/dashboard')
        }, 5000)
      } else {
        logger.log(nodeId, 'Order completed with errors', response)
        setBanner({
          message: `The order failed to fully update, please let someone at Meshify know and go back to the dashboard manually. ${response.error}`,
          severity: 'error',
          show: true,
        })
      }
    }
    actions()
  }, [order])

  useEffect(() => {
    const scannedSortFunction = (a: IOrderItem, b: IOrderItem) => Number(b.scanned) - Number(a.scanned)
    const unscannedSortFunction = (a: IOrderItem, b: IOrderItem) => scannedSortFunction(a, b) * -1
    const nameAscendingSortFunction = (a: IOrderItem, b: IOrderItem) => a.title.localeCompare(b.title)
    const nameDescendingSortFunction = (a: IOrderItem, b: IOrderItem) => nameAscendingSortFunction(a, b) * -1

    if (sortDropdownValue === '') {
      setSortFunction(undefined)
    } else if (sortDropdownValue === 'scanned') {
      setSortFunction(() => scannedSortFunction)
    } else if (sortDropdownValue === 'not scanned') {
      setSortFunction(() => unscannedSortFunction)
    } else if (sortDropdownValue === 'title ascending') {
      setSortFunction(() => nameAscendingSortFunction)
    } else if (sortDropdownValue === 'title descending') {
      setSortFunction(() => nameDescendingSortFunction)
    }
  }, [sortDropdownValue])

  const handleBulkItemPrompt = async (title: string, sku: string, remainingUnscanned: number) => {
    const previousRemember = localStorage.getItem(BULK_ITEM_REMEMBER + sku)
    let scanAll = false
    if (previousRemember === null) {
      // wait for user input, the modal will call the resolve method
      const usersInput = await new Promise<{ scanAll: boolean; rememberChoice: boolean }>((resolve) => {
        bulkItemResolve.current = resolve
        // show the modal
        setShowBulkItemPrompt({ title, sku, show: true, remaining: remainingUnscanned })
      })
      // close the modal after the user's input
      setShowBulkItemPrompt({ title: '', sku: '', show: false, remaining: -1 })

      if (usersInput.rememberChoice) {
        localStorage.setItem(BULK_ITEM_REMEMBER + sku, usersInput.scanAll.toString())
      }

      scanAll = usersInput.scanAll
    } else {
      scanAll = previousRemember === 'true'
    }

    return scanAll
  }

  const findScannedItem = async (scannedBarcode: BarcodeObject): Promise<{ itemFound: IOrderItem; needsActivation: boolean } | undefined> => {
    const scannedBarcodeIdentifier = 'imei' in scannedBarcode ? scannedBarcode.imei : scannedBarcode.sn
    const scannedItemsMatching = order.find((item: IOrderItem) => item.sku === scannedBarcode.sku && item.scanned)
    const unscannedItemsMatching = order.find((item: IOrderItem) => item.sku === scannedBarcode.sku && !item.scanned)
    const snItemsMatching = order.find((item: IOrderItem) => item.identifier && item.identifier === scannedBarcodeIdentifier && item.scanned)

    // if an item is scanned already and the SN matches the current scanned barcode, reject it
    if (snItemsMatching) {
      setScanError(ScanErrorTypes.DUPLICATE)
      return undefined
    }

    // if we can find the item but all are scanned - show that you've filled the allotted amount
    if (scannedItemsMatching && !unscannedItemsMatching) {
      setScanError(ScanErrorTypes.FILLED_ALLOTED_AMOUNT)
      return undefined
    }

    // if we can't find the item - return not found
    if (!unscannedItemsMatching) {
      setScanError(ScanErrorTypes.NOT_FOUND)
      return undefined
    }

    // check if the gateway has all "gateway" related attributes
    if (scannedBarcode.sku === 'TK-KONA-WT1' && scannedBarcode.type !== 'gateway') {
      setScanError(ScanErrorTypes.UNKNOWN_SCAN)
      return undefined
    }

    // check if the nbiot sensor has all the "leakAndFreeze" related attributes
    if (scannedBarcode.sku === 'SC-LM-1' && scannedBarcode.type !== 'leakAndFreeze') {
      setScanError(ScanErrorTypes.UNKNOWN_SCAN)
      return undefined
    }

    // if the barcode only has a sku - just return it immediately, no extra checks
    if (scannedBarcode.type === 'generic') {
      return { itemFound: unscannedItemsMatching, needsActivation: false }
    }

    const devicesFolderId = await getDevicesFolderId(scannedBarcode)
    let sameOrder = false
    // if device is already assigned to a folder id - we can't ship it
    if (devicesFolderId) {
      const parentQueries = [getDevicesOrder(devicesFolderId), getDevicesAccount(devicesFolderId)]
      await Promise.all(parentQueries).then(([orderResponse, accountResponse]) => {
        logger.log(nodeId, 'Device already active', { devicesFolderId, orderResponse, accountResponse })
        if (orderResponse) {
          if (orderResponse === orderId) {
            sameOrder = true
          } else {
            setScanError(`That device already exists on the order: ${orderResponse}`)
          }
        } else if (accountResponse) {
          setScanError(`That device already exists on the folder: ${accountResponse}`)
        } else {
          setScanError('That device already exists... somewhere.')
        }
      })

      if (!sameOrder) {
        return undefined
      }
    }

    // otherwise, this is a good item to ship
    return { itemFound: unscannedItemsMatching, needsActivation: !sameOrder }
  }

  const markItemAsScanned = (scannedItem: IOrderItem, identifier: string | undefined, scanAll: boolean): boolean => {
    setOrder((previousOrder) =>
      previousOrder.map((item: IOrderItem) => {
        const newItem = { ...item }
        if (newItem.id === scannedItem.id || (scanAll && scannedItem.sku === newItem.sku)) {
          newItem.scanned = true
          newItem.identifier = identifier
        }

        return newItem
      })
    )

    return true
  }

  const assignSignupKeyToDevice = async (scannedItem: IOrderItem, imei: string) => {
    try {
      const res = await Api.post('/api/hwhash/encode', { uniqueId: imei, hashType: 'nbiotfull' })
      const signupKey = res.ok && res.body?.hashId

      setOrder((previousOrder) =>
        previousOrder.map((item: IOrderItem) => {
          const newItem = { ...item }
          if (newItem.id === scannedItem.id) {
            newItem.signupKey = signupKey || FAILED_SIGNUP_KEY
          }

          return newItem
        })
      )
    } catch (e) {
      logger.log(nodeId, 'Signup key failure', e)
    }
  }

  return (
    <Grid container={true} spacing={4} data-cy="fulfillOrderPage">
      <Grid item={true} xs={12}>
        <Typography variant="h4">Fulfillment Order #{orderId}</Typography>
      </Grid>

      <Grid item={true} xs={12}>
        <Chip label="Unfulfilled" />
      </Grid>

      {loading && (
        <Grid item={true} xs={12}>
          <CircularProgress color="secondary" className={classes.spinner} data-testid="spinner" data-cy="loadingSpinner" />
        </Grid>
      )}

      {!loading && !error && (
        <Grid item={true} xs={12}>
          <Paper className={classes.orderWrapper}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <ContactInformation
                  nodeId={nodeId}
                  loading={contactLoading}
                  addressCode={addressCode}
                  contact={locationManager}
                  nbiotOnOrder={nbiotOnOrder}
                  folderId={folderId}
                />
              </Grid>

              {banner.show && (
                <Grid item={true} xs={12}>
                  <Alert variant="standard" severity={banner.severity}>
                    {banner.message}
                  </Alert>
                </Grid>
              )}
              <Grid item={true} xs={9} sm={8} md={9} lg={10} xl={10}>
                <Typography variant="subtitle2">Order list ({totalItems} items total)</Typography>
              </Grid>

              <Grid item={true} xs={3} sm={4} md={3} lg={2} xl={2}>
                <Typography variant="caption">order progress:</Typography>
                <LinearProgress variant="determinate" value={(scannedItems / totalItems) * 100} />
              </Grid>

              <Grid item={true} xs={9} sm={8} lg={10}>
                <TextField
                  variant="outlined"
                  placeholder="Search for an item"
                  size="small"
                  value={searchText}
                  onChange={(event) => setSearchText(event.target.value)}
                  inputProps={{
                    'data-testid': 'filter-textfield',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: searchText ? (
                      <InputAdornment position="end">
                        <IconButton size="small" onClick={() => setSearchText('')} data-testid="clear-search-button">
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ) : undefined,
                  }}
                />
              </Grid>

              <Grid item={true} xs={3} sm={4} lg={2}>
                <FormControl variant="outlined" fullWidth={true} size="small">
                  <InputLabel id="sort-by-label">Sort by</InputLabel>
                  <Select
                    labelId="sort-by-label"
                    data-testid="sort-by-select"
                    value={sortDropdownValue}
                    onChange={(event) => {
                      setSortDropdownValue(event.target.value as SortOptions)
                      localStorage.setItem(SORT_ITEMS, event.target.value.toString())
                    }}
                    variant="outlined"
                    label="Sort by"
                  >
                    <MenuItem value="">unsorted</MenuItem>
                    <MenuItem value="scanned">scanned first</MenuItem>
                    <MenuItem value="not scanned">unscanned first</MenuItem>
                    <MenuItem value="title ascending">name ascending</MenuItem>
                    <MenuItem value="title descending">name descending</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* empty grid to align next item properly */}
              <Grid item={true} xs={9} sm={8} lg={10} />

              <Grid item={true} xs={3} sm={4} lg={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={scrollOnScan}
                      onChange={(event) => {
                        setScrollOnScan(event.target.checked)
                        localStorage.setItem(SCROLL_ON_SCAN, event.target.checked.toString())
                      }}
                    />
                  }
                  label="Scroll on scan"
                />
              </Grid>

              <Grid item={true} xs={12}>
                <Grid container={true} spacing={2}>
                  {order
                    .filter((item: IOrderItem) => item.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
                    .sort(sortFunction)
                    .map((item) => (
                      <Grid item={true} xs={12} sm={6} md={4} lg={3} xl={3} key={item.id}>
                        <OrderItemCard item={item} refetchSignupKey={assignSignupKeyToDevice} loading={item.id === loadingItem} />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}

      <ModalDialog
        show={scanError !== undefined}
        dialogTitle="Scan Error"
        dialogContent={scanError}
        qrCodeAction={SCANNER_CODES.COMMAND_CONFIRM}
        closeModal={() => setScanError(undefined)}
        dialogActions={<Button onClick={() => setScanError(undefined)}>close</Button>}
      />

      <BulkItemModal
        show={showBulkItemPrompt.show}
        title={showBulkItemPrompt.title}
        sku={showBulkItemPrompt.sku}
        remaining={showBulkItemPrompt.remaining}
        resolver={bulkItemResolve.current}
      />
    </Grid>
  )
}

export default Order
