import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    logo: {
      textDecoration: 'none',
      color: theme.palette.common.white,
    },
    menuBtn: {
      marginLeft: 'auto',
      color: theme.palette.common.white,
    },
    userName: {
      textTransform: 'lowercase',
    },
    menuItem: {
      textDecoration: 'none',
      color: theme.palette.common.black,
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
