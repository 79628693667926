import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { createGenerateClassName, StylesProvider } from '@mui/styles'
import React from 'react'

export const fulfillmentPalette = {
  primary: {
    main: '#1F447E',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ff4081',
    main: '#f50057',
    dark: '#c51162',
    contrastText: '#fff',
  },
}

export const fulfillmentTheme = createTheme({
  typography: {
    fontFamily: ["'Work Sans', sans-serif"].join(','),
  },
  palette: {
    ...fulfillmentPalette,
  },
})

// we added custom prefix to material classname
// because autogenerated classNames were for some reason same as some classes that mui-datatables package generated
// which caused things to look weird
const generateClassName = createGenerateClassName({
  productionPrefix: 'muiCustom',
})

const MaterialThemeProvider: React.FC = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst={true}>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={fulfillmentTheme}>{children}</ThemeProvider>
      </StylesProvider>
    </StyledEngineProvider>
  )
}

export default MaterialThemeProvider
