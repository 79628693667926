import Api from '../../utils/api'
import { ErrorTypes } from './return-wrapper'

interface IResponse {
  ok: boolean
  error: number | undefined
  body: any | undefined
}

export const getReturnNode = async (returnId: string) => {
  const returnOrderNodeResponse = await Api.post('/api/nodes/query', {
    filters: [
      {
        fieldName: 'uniqueId',
        operator: 'eq',
        value: `return-${returnId}`,
      },
    ],
  })

  const returnObject: IResponse = {
    ok: true,
    error: undefined,
    body: undefined,
  }

  if (!returnOrderNodeResponse.ok) {
    returnObject.error = ErrorTypes.API_ERROR
    returnObject.ok = false
  } else if (returnOrderNodeResponse.body.results.length === 0) {
    returnObject.error = ErrorTypes.NOT_FOUND
    returnObject.ok = false
  } else {
    returnObject.body = returnOrderNodeResponse.body.results[0]
  }

  return returnObject
}

export const getReturnChannelData = async (nodeId: string) => {
  const returnOrderResponse = await Api.get(`/api/data/current?nodeId=${nodeId}`)

  const returnObject: IResponse = {
    ok: true,
    error: undefined,
    body: undefined,
  }

  if (!returnOrderResponse.ok) {
    returnObject.error = ErrorTypes.API_ERROR
    returnObject.ok = false
  } else if (!returnOrderResponse.body?.[nodeId]) {
    returnObject.error = ErrorTypes.NOT_FOUND
    returnObject.ok = false
  } else {
    returnObject.body = returnOrderResponse.body?.[nodeId]
  }

  return returnObject
}

export const getNodesInFolder = async (folderId: number) => {
  const nodesInFolderResponse = await Api.post('/api/nodes/query', {
    filters: [
      {
        fieldName: 'folderId',
        operator: 'eq',
        value: folderId,
      },
      {
        fieldName: 'nodeTypeName',
        operator: 'in',
        value: ['motion', 'motion2', 'probe', 'probe2', 'leak', 'leak2', 'leak3', 'temp3', 'gateway', 'nbiot_leak', 'cxamnas1'],
      },
    ],
  })

  if (!nodesInFolderResponse.ok) {
    return []
  }

  return nodesInFolderResponse.body.results
}

export const deleteClaimByUniqueId = async (uniqueId: string, deviceType: BarcodeObject['type'], allClaims: Partial<IClaimType>[]) => {
  // only check sercomm for leak and freeze devices
  const foundClaim =
    deviceType === 'leakAndFreeze'
      ? allClaims.find((claim) => claim.uniqueId?.toLowerCase() === uniqueId.toLowerCase() && claim.networkType === 'nbiot-sercomm')
      : allClaims.find((claim) => claim.uniqueId?.toLowerCase() === uniqueId.toLowerCase() && claim.networkType !== 'nbiot-sercomm')

  // if we can't find a claim, we assume it is deleted
  if (!foundClaim) {
    return { ok: true, error: undefined }
  }

  try {
    // try to delete the claim
    const deletedClaim = await Api.del(`/api/claims/${foundClaim.networkType}/${foundClaim.id}`)

    if (deletedClaim.ok) {
      return { ok: true, error: undefined }
    } else {
      return { ok: false, error: deletedClaim?.body?.detail }
    }
  } catch (e) {
    return { ok: false, error: 'Claim could not be deleted: ' + e }
  }
}

export const getAllNetworkClaims = async () => {
  const chripstackPromise = Api.get('/api/claims/lora-chirpstack')
  const tracknetPromise = Api.get('/api/claims/lora-tracknet')
  const sercommPromise = Api.get('/api/claims/nbiot-sercomm')

  return Promise.all([chripstackPromise, tracknetPromise, sercommPromise]).then(([chirpstackClaims, tracknetClaims, sercommClaims]) => {
    const claims = [] as Partial<IClaimType>[]
    if (!chirpstackClaims.ok || !tracknetClaims.ok || !sercommClaims.ok) {
      return undefined
    }

    const fn = (claim: IClaimType) =>
      claims.push({
        id: claim.id,
        uniqueId: claim.uniqueId,
        networkType: claim.networkType,
      })

    tracknetClaims.body.forEach(fn)
    chirpstackClaims.body.forEach(fn)
    sercommClaims.body.forEach(fn)

    return claims
  })
}

const twilioIccidIdentifier = '8988'

// three things might identify a twilio gateway:
// 1. the metadata iccid starts with the identifier
// 2. the channel iccid starts with the identifier
// 3. the apn has the word 'twilio' in it
const isTwilioGateway = async (gateway: INodeType) => {
  if (gateway.metadata.iccid?.toString()?.startsWith(twilioIccidIdentifier)) {
    return true
  }

  const channelsRes = await Api.get(`/api/data/current?nodeId=${gateway.id}`)
  if (!channelsRes.ok) {
    throw new Error(`Failed to fetch channels for gateway id ${gateway.id}`)
  }

  const channels = channelsRes.body[gateway.id]

  if (channels.iccid?.value?.toString()?.replace('sim-', '').startsWith(twilioIccidIdentifier)) {
    return true
  }

  if (channels.apn?.value?.toString()?.includes('twilio')) {
    return true
  }

  return false
}

export const findTwilioGateways = async (gateways: INodeType[]) => {
  const promises = gateways.map(isTwilioGateway)

  const responses = await Promise.all(promises)
    .then((res) => res)
    .catch((error) => error)

  if (responses instanceof Error) throw responses

  // if the response at the gateway's index is true, return the gateway node,
  // otherwise we will filter out the non-twilios
  return gateways.map((gateway, idx) => (responses[idx] ? gateway : undefined)).filter((x) => x) as INodeType[]
}
