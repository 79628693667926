import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = () =>
  createStyles({
    avatarLogo: {
      height: '48px',
      width: '48px',
    },
    qrCode: {
      display: 'block',
      margin: '16px',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
