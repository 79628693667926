import { MUIDataTableColumn } from 'mui-datatables'
import { ReactNode, useCallback } from 'react'
import { useHistory } from 'react-router'

import Api from '../../utils/api'
import { useTableState } from '../../utils/custom-hooks/use-table-state'
import { getFormattedDateTime } from '../../utils/helper-functions'
import Table from '../table'

interface IOrderTableProps {
  orderStatus: 'Fulfilled' | 'Submitted' | 'Shipped' | 'Delivered'
  tableTitle: string
  allowRowClick: boolean
}

const OrdersTable = (props: IOrderTableProps) => {
  const { orderStatus, tableTitle, allowRowClick } = props
  const history = useHistory()

  const columns = [
    { name: 'ID', options: { filter: false, sort: true } },
    { name: 'Date Created', options: { filter: false, sort: true, customBodyRender: (value: string) => getFormattedDateTime(new Date(value)) ?? '-' } },
    { name: 'Date Updated', options: { filter: false, sort: true, customBodyRender: (value: string) => getFormattedDateTime(new Date(value)) ?? '-' } },
    { name: 'Order Code', options: { filter: false, sort: true } },
    { name: 'Device Count', options: { filter: false, sort: false } },
    { name: 'Total Items Count', options: { filter: false, sort: false } },
  ]

  const fetchTableData = async () => {
    const res = await Api.get(`/api/v2/fulfillment/orders?status=${orderStatus}&limit=200`)

    if (!res.ok) {
      return Promise.reject(res.body.message ?? `Something went wrong and we could not load ${tableTitle}. Please try again later`)
    }

    const deviceSkus = ['SC-LM-1', 'MY-MD1', 'MY-LD1', 'MY-TD1', 'TK-KONA-WT1']

    const tableData = res.body.rows
    const allRowData: (number | string | ReactNode)[][] = tableData.map((item: IOrderDetails) => {
      let totalItemsCount = 0
      let deviceCount = 0
      let isJsonValid = false
      try {
        const parsedOrderItems = JSON.parse(item.orderItems)
        isJsonValid = true

        parsedOrderItems.forEach((orderItem: IOrderItemDetails) => {
          totalItemsCount += orderItem.quantity
          if (deviceSkus.includes(orderItem.sku)) {
            deviceCount += orderItem.quantity
          }
        })
      } catch (e) {
        isJsonValid = false
      }

      return [
        item.id,
        item.createdAt,
        item.updatedAt,
        item.shortOrderCode,
        isJsonValid ? deviceCount : 'Not Available',
        isJsonValid ? totalItemsCount : 'Not Available',
      ]
    })

    return Promise.resolve({ data: allRowData })
  }

  const memoizedFetch = useCallback(fetchTableData, [])
  const state = useTableState(memoizedFetch)

  const redirectToOrder = (rowData: string[]) => {
    const orderCode = rowData[3]
    if (orderCode.startsWith('shp-')) {
      const orderNum = orderCode.substring(4)
      history.push(`/order/${orderNum}`)
    }
  }

  return (
    <Table
      title={tableTitle}
      columns={columns as MUIDataTableColumn[]}
      loading={state.loading}
      error={state.error}
      data={state.data}
      options={{
        search: true,
        pagination: true,
        print: true,
        onRowClick: (rowData: string[]) => (allowRowClick ? redirectToOrder(rowData) : null),
      }}
    />
  )
}

export default OrdersTable
