import { MUIDataTableColumn, MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'

import { ClientsideTableFooter as CustomFooter } from './custom-footer'
import StatefulTable from './stateful-table'

type StatefulTableProps = Omit<MUIDataTableProps, 'columns' | 'data' | 'title'> & {
  loading: boolean
  error: Error | { message: string } | null
  title: string | React.ReactNode
}

type InternalProps = {
  columns: MUIDataTableColumn[] | string[]
  title: string | React.ReactNode
  options: MUIDataTableOptions
  data: React.ReactNode[][]
} & Omit<StatefulTableProps, 'width'>

type TableProps = StatefulTableProps & {
  columns: MUIDataTableColumn[] | string[]
  data: React.ReactNode[][] | null /* Array of Arrays where each subarray is a row with the entries corresponding to columns in order */
  title: string | React.ReactElement
}

export const ClientSidePaginatedTable = (props: TableProps) => {
  const { columns, data, error, loading, options, title } = props
  const { enqueueSnackbar } = useSnackbar()

  const tableProps: InternalProps = useMemo(() => {
    // pagination is enabled by default, unless explicitly set to false within a given individual Table component
    const hasPagination = options?.pagination ?? true

    return {
      title,
      columns,
      options: {
        pagination: hasPagination,
        viewColumns: false,
        search: options?.search ?? true,
        searchOpen: false,
        download: options?.download ?? true,
        filter: false,
        print: options?.print ?? false,
        selectableRows: 'none',
        setTableProps: () => {
          return {
            size: 'small', // this makes table denser
          }
        },
        customFooter: (
          totalRowCount: number,
          currentPageNumber: number,
          currentRowsPerPage: number,
          changeRowsPerPage: (n: React.ReactText) => void,
          changePage: (n: number) => void
        ) => {
          return hasPagination ? (
            <CustomFooter
              count={totalRowCount}
              rowsPerPage={currentRowsPerPage}
              changeRowsPerPage={changeRowsPerPage}
              changePage={changePage}
              currentPageNumber={currentPageNumber}
              onChangePage={(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, pageNum: number) => {
                changePage(pageNum)
              }}
            />
          ) : (
            <></>
          )
        },
        ...options,
      },
      data: data ?? [[]],
      error: error ? new Error(error.message) : null,
      loading: loading ?? false,
    }
  }, [columns, loading, title, data, error, options, enqueueSnackbar])

  return <StatefulTable {...tableProps} columns={columns} />
}

export default ClientSidePaginatedTable
