import CircularProgress from '@mui/material/CircularProgress'
import MUIDataTable from 'mui-datatables'

import useTableStyles from './table.styles'

const LoadingSpinnerRow = () => {
  const classes = useTableStyles()

  return (
    <div data-testid="table-loader" data-cy="tableSpinner" className={classes.tableLoader}>
      <CircularProgress color="secondary" />
    </div>
  )
}

const LoadingTable = ({ title }: { title: string | React.ReactNode }) => {
  const props = {
    title,
    columns: [
      {
        name: '',
        options: {
          customBodyRender: () => <LoadingSpinnerRow />,
        },
      },
    ],
    data: [['just a placeholder to ensure a row is rendered']],
    options: {
      elevation: 1,
      page: 0,
      filter: false,
      print: false,
      viewColumns: false,
      download: false,
      search: false,
      selectableRows: 'none' as const,
      customFooter: () => {
        return <tbody />
      },
    },
  }
  return <MUIDataTable {...props} />
}

export default LoadingTable
