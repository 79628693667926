import { grey } from '@mui/material/colors'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const DRAWER_WIDTH = 240

export const styles = () =>
  createStyles({
    menuDrawer: {
      zIndex: 99,
      width: `${DRAWER_WIDTH}px`,
      boxShadow: '0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },
    drawerPaper: {
      width: `${DRAWER_WIDTH}px`,
      boxSizing: 'border-box',
    },
    listItem: {
      '&:hover': {
        backgroundColor: grey[100],
      },
    },
    listButton: {
      color: 'black',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
