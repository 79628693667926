import { Add } from '@mui/icons-material'
import { Autocomplete, Button, CircularProgress, createFilterOptions, Grid, IconButton, TextField } from '@mui/material'
import debounce from 'lodash.debounce'
import { useSnackbar } from 'notistack'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { startTransition, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import Table from '../../components/table'
import api from '../../utils/api'
import { getAdminUrl } from '../../utils/helper-functions'
import { areUniqueIdsEqual } from '../return'

interface InsightsEquipmentInventory {
  accountName: string
  accountNumber: string
  deviceId: string
  deviceName: string
  deviceOwnership: string
  deviceStatus: string
  deviceType: string
  locationDescription: string | null
  programName: string
  reportDate: string
  sensorLocation: string | null
  type: string
}

type OrderNumberColumn = { id: number; shortOrderNumber: string | undefined }
type ReturnNumberColumn = { id: number; uniqueId: string | undefined }
type EquipmentHistoryTableData = [
  string, // device name
  string, // device unique id
  string, // device status
  string, // node type
  OrderNumberColumn, // order id, order short order number
  ReturnNumberColumn, // return id, return unique id
  string // device name
]

type ActionableDevicesTableData = [
  string, // site name [0]
  string, // old sensor vanity [1]
  string, // new sensor vanity [2]
  string, // old device location [3]
  string // notes [4]
]

const Replacements = () => {
  const { enqueueSnackbar } = useSnackbar()

  const [equipmentData, setEquipmentData] = useState<InsightsEquipmentInventory[]>([])
  const [equipmentLoading, setEquipmentLoading] = useState<boolean>(false)
  const [swappingLoading, setSwappingLoading] = useState<boolean>(false)
  const [nodes, setNodes] = useState<INodeType[]>([])

  // table data for the top table - insights equipment data
  const [tableData, setTableData] = useState<EquipmentHistoryTableData[]>([])
  // table data for the bottom table - user picks these records
  const [oldDevices, setOldDevices] = useState<ActionableDevicesTableData[]>([])

  const [searchFolderList, setSearchFolderList] = useState<IFolderType[]>([])
  const [searchFolderInput, setSearchFolderInput] = useState<string>('')

  const oldDeviceVanityNames = oldDevices.map((od) => od[1])
  const newDeviceVanityNames = oldDevices.map((od) => od[2])
  const replacementDevicesOptions = useMemo(() => {
    const devices = equipmentData.map((ed) => ed.deviceName).concat(nodes.map((n) => n.vanity))
    return [...new Set(devices)]
  }, [equipmentData, nodes])

  const abortController = useRef<AbortController>()

  // adds a new row to the bottom table, this will be the list of "old devices" that
  // indicate that they need replacement.
  const addToOldList = (deviceName: string) => {
    const node = nodes.find((n) => n.vanity === deviceName)
    const equipmentItem = equipmentData.find((d) => d.deviceName === deviceName)

    if (equipmentItem) {
      const locationItems = node
        ? [node?.metadata?.device_location, node?.metadata?.device_location_note]
        : [equipmentItem.sensorLocation, equipmentItem.locationDescription]
      const location = locationItems.filter((x) => x).join(', ')

      const newRow: ActionableDevicesTableData = [
        equipmentItem.accountName,
        equipmentItem.deviceName,
        '',
        location.length > 2 ? location : '(missing location)',
        '',
      ]
      setOldDevices((od) => [...od, newRow])
    }
  }

  const allEquipmentColumns = [
    { name: 'Device Name' },
    { name: 'Device Unique Id' },
    { name: 'Device Status' },
    { name: 'Node Type' },
    {
      name: 'Order #',
      options: {
        customBodyRender: (order: OrderNumberColumn) =>
          order.id > 0 ? (
            <Link to={{ pathname: `${getAdminUrl()}/nodes/${order.id}` }} target="_blank">
              {order.shortOrderNumber}
            </Link>
          ) : (
            order.shortOrderNumber
          ),
        sortCompare: (order: 'asc' | 'desc') => (a: { data: OrderNumberColumn }, b: { data: OrderNumberColumn }) =>
          (a.data.id - b.data.id) * (order === 'asc' ? 1 : -1),
      },
    },
    {
      name: 'Return #',
      options: {
        customBodyRender: (returnNumber: ReturnNumberColumn) =>
          returnNumber.id > 0 ? (
            <Link to={{ pathname: `${getAdminUrl()}/nodes/${returnNumber.id}` }} target="_blank">
              {returnNumber.uniqueId}
            </Link>
          ) : (
            returnNumber.uniqueId
          ),
        sortCompare: (order: 'asc' | 'desc') => (a: { data: ReturnNumberColumn }, b: { data: ReturnNumberColumn }) =>
          (a.data.id - b.data.id) * (order === 'asc' ? 1 : -1),
      },
    },
    {
      name: 'Add Old',
      options: {
        customBodyRender: (deviceName: string) => (
          <IconButton
            onClick={() => addToOldList(deviceName)}
            disabled={oldDeviceVanityNames.includes(deviceName) || newDeviceVanityNames.includes(deviceName)}
          >
            <Add />
          </IconButton>
        ),
      },
    },
  ]

  const replacementColumns = useMemo(
    () => [
      // ['Site', 'Old Sensor', 'New Sensor', 'Location', 'Notes']
      { name: 'Site' },
      { name: 'Old Sensor' },
      {
        name: 'New Sensor',
        options: {
          customBodyRender: (_: any, data: any) => {
            return (
              <Autocomplete
                sx={{ width: '400px' }}
                options={replacementDevicesOptions}
                getOptionDisabled={(option) => oldDeviceVanityNames.includes(option) || newDeviceVanityNames.includes(option)}
                onChange={(__, newValue) => {
                  const oldDeviceRecord = oldDevices[data.rowIndex]
                  if (oldDeviceRecord) {
                    const replaceRecord: ActionableDevicesTableData = [...oldDeviceRecord]
                    replaceRecord[data.columnIndex] = newValue ?? ''

                    setOldDevices((od) => od.map((odItem, idx) => (idx === data.rowIndex ? replaceRecord : odItem)))
                  }
                }}
                autoHighlight={true}
                renderInput={(params) => <TextField {...params} />}
              />
            )
          },
        },
      },
      { name: 'Location' },
      {
        name: 'Notes',
        options: {
          customBodyRender: (_: any, data: any) => {
            return (
              <TextField
                onChange={(e) => {
                  const oldDeviceRecord = oldDevices[data.rowIndex]
                  if (oldDeviceRecord) {
                    const replaceRecord: ActionableDevicesTableData = [...oldDeviceRecord]
                    replaceRecord[data.columnIndex] = e.target.value ?? ''

                    startTransition(() => setOldDevices((od) => od.map((odItem, idx) => (idx === data.rowIndex ? replaceRecord : odItem))))
                  }
                }}
              />
            )
          },
        },
      },
    ],
    [setOldDevices, oldDeviceVanityNames, newDeviceVanityNames]
  )

  const fetchFolders = useMemo(
    () =>
      debounce(async (query: string) => {
        if (abortController) {
          // if a previous abort controller is defined, abort the request
          if (abortController.current) {
            abortController.current.abort()
          }
          // assign the new abort controller to the passed in ref
          abortController.current = new AbortController()
        }

        const res = await api.post(
          'api/v2/insights/folders',
          {
            limit: 200,
            query,
            folder_types: ['Participant'],
          },
          { signal: abortController?.current?.signal }
        )

        if (res.aborted) {
          return
        }

        if (res.ok) {
          setSearchFolderList(res?.body?.rows ?? [])
        } else {
          setSearchFolderList([])
        }
      }, 500),
    [setSearchFolderList]
  )

  useEffect(() => {
    fetchFolders(searchFolderInput)
  }, [searchFolderInput, fetchFolders])

  const fetchData = async (fetchFolderId: string) => {
    setEquipmentData([])
    setOldDevices([])
    setNodes([])
    setEquipmentLoading(true)

    // get all equipment data (includes removed nodes)
    const resEquipment = await api.post('api/v2/insights/equipment-inventory', {
      folderIds: [Number(fetchFolderId)],
    })

    if (!resEquipment.ok) {
      setEquipmentLoading(false)
      enqueueSnackbar('Failed to fetch the insights data, try again?', { variant: 'error' })
      return
    }

    // get all node data
    const resNodes = await api.post(`api/nodes/query`, {
      filters: [
        {
          fieldName: 'folderId',
          operator: 'eq',
          value: Number(fetchFolderId),
        },
      ],
      first: 1000,
    })

    if (!resNodes.ok) {
      setEquipmentLoading(false)
      enqueueSnackbar('Failed to fetch the nodes data from carbon, try again?', { variant: 'error' })
      return
    }

    const fetchedEquipmentData = resEquipment.body.rows as InsightsEquipmentInventory[]
    const nodeData = resNodes.body.results as INodeType[]
    const orderNodes = nodeData.filter((n) => n.nodeTypeName === 'order')
    const returnNodes = nodeData.filter((n) => n.nodeTypeName === 'return')

    // get all return nodes channel data
    const returnNodeIds = nodeData.filter((n) => n.nodeTypeName === 'return').map((r) => r.id)
    let returnChannelData = {} as Record<number, any>
    if (returnNodeIds.length > 0) {
      const resReturnChannelData = await api.get(`api/data/current?nodeId=${returnNodeIds.join('&nodeId=')}`)

      if (!resReturnChannelData.ok) {
        setEquipmentLoading(false)
        enqueueSnackbar('Failed to fetch the channel data for returns, try again?', { variant: 'error' })
        return
      }
      returnChannelData = resReturnChannelData.body as Record<number, any>
    }

    setEquipmentData(fetchedEquipmentData)
    setNodes(nodeData)

    const td = fetchedEquipmentData.map((d) => {
      const node = nodeData.find((n) => n.vanity === d.deviceName)
      const shortOrderNumber = node?.metadata?.shortOrderNumber
      const orderNode = orderNodes.find((o) => o.uniqueId === `shp-${shortOrderNumber}`)

      const returnNode = returnNodes.find((r) => {
        if (returnChannelData?.[r.id]?.return_items?.value) {
          // json string of the unique id list
          const returnedItemsRaw = returnChannelData[r.id].return_items.value
          const returnedItems = JSON.parse(returnedItemsRaw)
          const found = returnedItems.find((uniqueId: string) => areUniqueIdsEqual(uniqueId, node?.uniqueId ?? '-1'))
          if (found) {
            return true
          }
        }
        return false
      })

      const row: EquipmentHistoryTableData = [
        d.deviceName,
        d.deviceId,
        d.deviceStatus,
        node?.nodeTypeName ?? 'unknown',
        { id: orderNode?.id ?? 0, shortOrderNumber },
        { id: returnNode?.id ?? 0, uniqueId: returnNode?.uniqueId },
        d.deviceName ?? 0,
      ]
      return row
    })

    setTableData(td)
    setEquipmentLoading(false)
  }

  // custom table search function
  // forces case insensitivity, allows comma seperated searching, allows searching in objects
  const customSearchText = useMemo(
    () => (toFind: string, toSearch: any) => {
      toFind = toFind.toLowerCase()
      const toFindArr = toFind.split(',')

      // allow comma seperated searching
      for (const f of toFindArr) {
        if (f.length === 0) {
          continue
        }
        for (const val of toSearch) {
          if (val?.toLowerCase?.()?.indexOf?.(f) >= 0) return true
          if (typeof val === 'object') {
            if (JSON.stringify(val).toLowerCase().indexOf(f) >= 0) return true
          }
        }
      }

      return false
    },
    []
  )

  // function to swap the location data (and some metadata) from the old device to the new device.
  // this will take all the selected rows from the table and iterate over them.
  const swapOldAndNew = useMemo(
    () => async (selectedRows: any) => {
      let didAnyFail = false

      // mui-datatables only gives us the index of the selected rows, so gather the actual rows here
      const rows = oldDevices.map((d, idx) => (selectedRows.lookup[idx] ? d : undefined)).filter((x) => x) as ActionableDevicesTableData[]

      setSwappingLoading(true)
      for (const row of rows) {
        // find old device node
        const oldDeviceNode = nodes.find((n) => n.vanity === row[1])
        const oldDeviceDeleted = equipmentData.find((ed) => ed.deviceName === row[1])
        const location = oldDeviceNode?.metadata?.device_location ?? oldDeviceDeleted?.sensorLocation ?? ''
        const locationNotes = oldDeviceNode?.metadata?.device_location_note ?? oldDeviceDeleted?.locationDescription ?? ''

        // find new device node
        const newDeviceNode = nodes.find((n) => n.vanity === row[2])

        if (!newDeviceNode) {
          // if there is no new node found, we can't update anything
          enqueueSnackbar(`Skipped row because there is no new node selected: ${row[1]}`, { persist: true })
          didAnyFail = true
          continue
        }

        if (!location && !locationNotes) {
          // if there is no new locations, there's no point in updating anything
          enqueueSnackbar(`Skipped row because there is no location to update: ${row[1]} + ${row[2]}`, { persist: true })
          didAnyFail = true
          continue
        }

        // update new node
        const newNodeRes = await api.put(`api/nodes/${newDeviceNode.id}`, {
          ...newDeviceNode,
          metadata: {
            ...newDeviceNode.metadata,
            device_location: location,
            device_location_note: locationNotes,
            swapped_from: JSON.stringify({
              id: oldDeviceNode?.id,
              vanity: oldDeviceNode?.vanity ?? oldDeviceDeleted?.deviceName ?? '',
              notes: row[4],
            }),
          },
        })

        if (!newNodeRes.ok) {
          enqueueSnackbar(`Failed to update the new node with name: ${newDeviceNode.vanity}`, { variant: 'error' })
          didAnyFail = true
          continue
        }

        // update old node
        if (oldDeviceNode) {
          const oldNodeRes = await api.put(`api/nodes/${oldDeviceNode.id}`, {
            ...oldDeviceNode,
            isActive: false, // disable old node after swap
            metadata: {
              ...oldDeviceNode.metadata,
              swapped_to: JSON.stringify({
                id: newDeviceNode?.id,
                vanity: newDeviceNode?.vanity,
                notes: row[4],
              }),
            },
          })

          if (!oldNodeRes.ok) {
            enqueueSnackbar(`Failed to update the old node with name: ${oldDeviceNode.vanity}`, { variant: 'error' })
            didAnyFail = true
          }
        }

        // artifical delay to prevent too many calls at once
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(true)
          }, 250)
        })
      }
      if (didAnyFail) {
        enqueueSnackbar('Process is done, some records did not work.', { variant: 'warning' })
      } else {
        enqueueSnackbar('Process is done', { variant: 'success' })
      }
      setSwappingLoading(false)
    },
    [oldDevices]
  )

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={6}>
        <Autocomplete
          autoHighlight={true}
          getOptionLabel={(option) => option.name}
          options={searchFolderList}
          onInputChange={(event, newInputValue) => {
            setSearchFolderInput(newInputValue)
          }}
          onChange={(_, newValue) => {
            if (newValue) {
              fetchData(newValue.id.toString())
            }
          }}
          renderInput={(params) => <TextField {...params} fullWidth={true} label="Folder Name" />}
          filterOptions={createFilterOptions({
            limit: 50,
          })}
        />
      </Grid>

      <Grid item={true} xs={12}>
        <Table
          title="Equipment History"
          columns={allEquipmentColumns}
          loading={equipmentLoading}
          error={null}
          data={tableData}
          options={{
            customSearch: customSearchText,
          }}
        />
      </Grid>

      <Grid item={true} xs={12} justifyItems="flex-end">
        <Table
          title="Actionable devices"
          columns={replacementColumns}
          loading={false}
          error={null}
          data={oldDevices}
          options={{
            sort: false,
            pagination: false,
            search: false,
            selectableRows: 'multiple',
            customSearch: customSearchText,
            customToolbarSelect: (selectedRows) => {
              return (
                <Button
                  onClick={() => swapOldAndNew(selectedRows)}
                  startIcon={swappingLoading ? <CircularProgress size="20px" /> : undefined}
                  disabled={swappingLoading}
                >
                  Swap old to new
                </Button>
              )
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default Replacements
