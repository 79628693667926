import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = () =>
  createStyles({
    closeIcon: {
      color: 'white',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
