import { createStyles, makeStyles } from '@mui/styles'

const styles = () => {
  return createStyles({
    wrapper: {
      flexShrink: 0,
    },
    pageNum: {
      margin: '7px 3px 0 3px',
      display: 'inline-block',
    },
  })
}
const useStyles = makeStyles(styles)
export default useStyles
