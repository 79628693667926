import CssBaseline from '@mui/material/CssBaseline'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import './index.css'
import { AllProviders } from './providers'
import * as serviceWorker from './serviceWorker'

const environment = process.env.REACT_APP_ENV

if (environment !== 'local' && environment !== 'development') {
  const userAgent = (window as any)?.navigator?.userAgent
  if (userAgent) {
    Sentry.init({
      dsn: 'https://e916e6c60b4e4275b7a79584e45efd43@o114884.ingest.sentry.io/5976498',
      integrations: [new Integrations.BrowserTracing()],
      environment,
    })
  }
}

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(
  <StrictMode>
    <AllProviders>
      <BrowserRouter>
        <CssBaseline />
        <App />
      </BrowserRouter>
    </AllProviders>
  </StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
