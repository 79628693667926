import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = (theme: Theme) =>
  createStyles({
    orderWrapper: {
      padding: theme.spacing(2),
    },
    scannerLabel: {
      float: 'right',
    },
    doneQRcode: {
      clear: 'right',
      float: 'right',
      marginTop: theme.spacing(),
    },
    dimmedQRcode: {
      opacity: '0.1',
    },
    finishReturnButton: {
      clear: 'right',
      float: 'right',
      marginTop: theme.spacing(),
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
