import AssignmentLateIcon from '@mui/icons-material/AssignmentLate'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HomeIcon from '@mui/icons-material/Home'
import InventoryIcon from '@mui/icons-material/Inventory'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import QrCodeScanner from '@mui/icons-material/QrCodeScanner'
import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material'
import { useContext } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import { AuthenticationContext } from '../../providers'
import useStyles from './index.styles'

const menuItems = [
  { name: 'Home', url: '/dashboard', icon: <HomeIcon /> },
  { name: 'Submitted Orders', url: '/submitted-orders', icon: <AssignmentLateIcon /> },
  { name: 'Fulfilled Orders', url: '/fulfilled-orders', icon: <CheckCircleIcon /> },
  { name: 'Shipped Orders', url: '/shipped-orders', icon: <LocalShippingIcon /> },
  { name: 'Delivered Orders', url: '/delivered-orders', icon: <InventoryIcon /> },
]

const Sidenav = () => {
  const classes = useStyles()
  const location = useLocation()
  const { ownUser } = useContext(AuthenticationContext)

  const isMeshifyUser = ownUser?.email?.includes('@meshify')

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      className={classes.menuDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <List>
        {menuItems.map((sidenavItem, index) => (
          <ListItem
            key={index}
            disablePadding={true}
            className={classes.listItem}
            selected={location.pathname === sidenavItem.url}
            component={Link}
            to={sidenavItem.url}
          >
            <ListItemButton className={classes.listButton}>
              <ListItemIcon>{sidenavItem.icon}</ListItemIcon>
              <ListItemText primary={sidenavItem.name} />
            </ListItemButton>
          </ListItem>
        ))}
        {isMeshifyUser && (
          <>
            <Divider />
            <ListItem disablePadding={true} className={classes.listItem} selected={location.pathname === '/device-info'} component={Link} to="/device-info">
              <ListItemButton className={classes.listButton}>
                <ListItemIcon>
                  <QrCodeScanner />
                </ListItemIcon>
                <ListItemText primary="Device Information" />
              </ListItemButton>
            </ListItem>
          </>
        )}
      </List>
    </Drawer>
  )
}

export default Sidenav
