import { Close as IconClose } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'

import useCloseBtnStyles from './index.styles'

const SnackbarCloseButton = ({ snackbarKey }: { snackbarKey: React.ReactText }) => {
  const { closeSnackbar } = useSnackbar()
  const classes = useCloseBtnStyles()

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)} data-testid="snackbar-close-btn" size="large">
      <IconClose fontSize="small" className={classes.closeIcon} />
    </IconButton>
  )
}

export default SnackbarCloseButton
