import { Alert, Grid } from '@mui/material'

import OrdersTable from '../../components/orders-table'

const SubmittedOrders = () => {
  return (
    <Grid container={true} spacing={1}>
      <Grid item={true} xs={12} sm={12} md={12} lg={10} xl={7}>
        <Alert variant="standard" severity="info">
          This table does not always show the most up-to-date information. The data in the table may be slightly delayed
        </Alert>
      </Grid>

      <Grid item={true} xs={12} data-cy="submittedOrdersTable">
        <OrdersTable orderStatus="Submitted" tableTitle="Submitted Orders" allowRowClick={true} />
      </Grid>
    </Grid>
  )
}

export default SubmittedOrders
