import { green } from '@mui/material/colors'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = () =>
  createStyles({
    successBackground: {
      background: green[500],
    },
    successCheckCircle: {
      color: 'white',
      position: 'absolute',
      right: '10px',
      bottom: '10px',
    },
    loadingIcon: {
      position: 'absolute',
      right: '10px',
      bottom: '10px',
    },
    orderCard: {
      display: 'flex',
      height: '100%',
    },
    cardWrapper: {
      height: '100%',
      position: 'relative',
      flexGrow: 1,
      minWidth: 0,
    },
    cardHeader: {
      display: 'block',
      overflow: 'hidden',
    },
    errorIcon: {
      marginLeft: '4px',
    },
    twilioMark: {
      padding: '8px',
      backgroundColor: '#F22F46', // twilio brand color
      writingMode: 'vertical-lr',
      textOrientation: 'upright',
      fontWeight: 600,
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
