import { grey } from '@mui/material/colors'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = () =>
  createStyles({
    mainContentWrapper: {
      height: '100%',
      padding: '20px',
      overflow: 'hidden',
      width: '100%',
    },
    messageGridItem: {
      padding: 30,
      backgroundColor: grey[100],
      borderRadius: 5,
    },
    errorImg: {
      marginBottom: '10px',
    },
    divider: {
      margin: 10,
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
