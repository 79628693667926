import ClearIcon from '@mui/icons-material/Clear'
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

import useScannerCapture from '../../utils/custom-hooks/use-scanner-capture'
import { simulateScanner } from '../../utils/helper-functions'
import useStyles from './index.styles'

const DeveloperArea = () => {
  const classes = useStyles()

  const scanHistory = useScannerCapture()
  const [lastCommand, setLastCommand] = useState<string | undefined>(undefined)
  const [mockCommand, setMockCommand] = useState<string>('')
  const [showArea, setShowArea] = useState<boolean>(false)

  useEffect(() => {
    setLastCommand(scanHistory.slice(-1).pop())
  }, [scanHistory])

  useEffect(() => {
    const eventListener = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'd') {
        setShowArea((oldArea) => !oldArea)
        event.preventDefault()
      }
    }

    document.addEventListener('keydown', eventListener)
    return () => document.removeEventListener('keydown', eventListener)
  }, [])

  if (!showArea) return null

  return (
    <Grid container={true} alignItems="center" className={classes.wrapper}>
      <Grid item={true} xs={true}>
        {lastCommand}
      </Grid>

      <Grid item={true}>
        <TextField
          data-cy="deviceCodeInput"
          placeholder="insert code"
          size="small"
          variant="outlined"
          value={mockCommand}
          onChange={(e) => {
            setMockCommand(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              simulateScanner(mockCommand)
              setMockCommand('')
            }
          }}
          InputProps={{
            endAdornment: mockCommand ? (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => setMockCommand('')} data-testid="clear-search-button">
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ) : undefined,
          }}
          inputProps={{
            'aria-label': 'developer-code-input',
          }}
        />
      </Grid>
    </Grid>
  )
}

export default DeveloperArea
