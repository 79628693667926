import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = () =>
  createStyles({
    crlfBarcode: {
      objectFit: 'cover',
      maxWidth: '250px',
    },
    volumeBarcodes: {
      objectFit: 'cover',
      maxWidth: '400px',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
