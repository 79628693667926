import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import { Card, CardContent, CardHeader, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material'
import classNames from 'classnames'

import { FAILED_SIGNUP_KEY } from '../../utils/constants'
import useStyles from './index.styles'

interface IProps {
  item: IOrderItem | IReturnItem
  refetchSignupKey?: (item: IOrderItem, imei: string) => void
  loading?: boolean
  isTwilioGateway?: boolean
}

interface IOrderCardContentProps {
  item: IOrderItem
  refetchSignupKey?: (item: IOrderItem, imei: string) => void
}

const OrderCardContent = (props: IOrderCardContentProps) => {
  const classes = useStyles()

  const { refetchSignupKey, item } = props
  const { sku, signupKey } = item

  return (
    <CardContent>
      <Typography variant="body2">
        {sku}
        {signupKey !== FAILED_SIGNUP_KEY ? (
          signupKey && ` - ${signupKey}`
        ) : refetchSignupKey ? (
          <Tooltip title="The signup key for this item failed to generate. Click to try again.">
            <IconButton
              size="small"
              onClick={() => refetchSignupKey(item, item.identifier as string)}
              className={classes.errorIcon}
              data-testid="order-item-error-button"
            >
              <ErrorIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
      </Typography>
    </CardContent>
  )
}

interface IReturnCardContentProps {
  uniqueId: string
}

const ReturnCardContent = (props: IReturnCardContentProps) => {
  const { uniqueId } = props

  return (
    <CardContent>
      <Typography variant="body2">{uniqueId}</Typography>
    </CardContent>
  )
}

const OrderItemCard = (props: IProps) => {
  const { item, refetchSignupKey, isTwilioGateway, loading } = props
  const { scanned, title } = item
  const classes = useStyles()

  return (
    <Card
      data-cy="orderItem"
      className={classNames(classes.orderCard, { [classes.successBackground]: scanned })}
      data-testid="order-item-card"
      id={`order-item-card-${item.id}`}
    >
      {isTwilioGateway && (
        <div className={classes.twilioMark} data-testid="twilio-marker">
          TWILIO
        </div>
      )}
      <div className={classes.cardWrapper}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <Tooltip title={title} arrow={true} placement="top">
              <Typography noWrap={true} variant="h5">
                {title}
              </Typography>
            </Tooltip>
          }
        />

        {'uniqueId' in item ? <ReturnCardContent uniqueId={item.uniqueId} /> : <OrderCardContent item={item} refetchSignupKey={refetchSignupKey} />}

        <div>
          {scanned && <CheckCircleIcon className={classes.successCheckCircle} aria-label="done" data-cy={`${item.title}-done`} cy-scan="scanSuccess" />}
          {loading && <CircularProgress className={classes.loadingIcon} size="24px" data-testid="item-loading" data-cy="evaluatingScan" />}
        </div>
      </div>
    </Card>
  )
}

export default OrderItemCard
