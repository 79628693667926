import { Button, Checkbox, FormControlLabel } from '@mui/material'
import { useState } from 'react'

import ModalDialog from '../modal-dialog'

interface BulkItemModalProps {
  title: string
  sku: string
  show: boolean
  remaining: number
  resolver: BulkItemResolverFunctionType | undefined
}

const BulkItemModal = (props: BulkItemModalProps) => {
  const { title, sku, show, remaining, resolver } = props

  const [rememberChoice, setRememberChoice] = useState<boolean>(false)

  return (
    <ModalDialog
      show={show}
      dialogTitle="Bulk item"
      dialogContent={
        <>
          <div>
            There are {remaining} <b>{title}</b> with the sku <b>{sku}</b> that still need to be scanned. Do you want all of these items to be scanned?
          </div>

          <div>
            <FormControlLabel
              control={<Checkbox value={rememberChoice} onChange={(e) => setRememberChoice(e.target.checked)} />}
              label="Remember this choice"
            />
          </div>
        </>
      }
      closeModal={() => resolver?.({ scanAll: false, rememberChoice: false })}
      dialogActions={[
        <Button key="scan one" onClick={() => resolver?.({ scanAll: false, rememberChoice })} data-cy="scanOneBtn">
          scan one
        </Button>,
        <Button key="scan all" onClick={() => resolver?.({ scanAll: true, rememberChoice })} data-cy='multiScanBtn'>
          scan all {remaining} items
        </Button>,
      ]}
    />
  )
}

export default BulkItemModal
