import { Theme } from '@mui/material'
import { grey } from '@mui/material/colors'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import loginBackground from '../../images/login-img.jpg'

export const styles = (theme: Theme) =>
  createStyles({
    loginImage: {
      backgroundImage: `url(${loginBackground})`,
      height: '100vh',
    },
    footer: {
      paddingBottom: theme.spacing(),
      [theme.breakpoints.up('md')]: {
        height: '100vh',
      },
      [theme.breakpoints.down('md')]: {
        position: 'fixed',
        bottom: 0,
      },
    },
    copyright: {
      color: grey[900],
    },
    link: {
      color: grey[900],
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    meshifySpan: {
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '64px',
      letterSpacing: '0.055em',
      color: theme.palette.secondary.main,
      textTransform: 'uppercase',
    },
    fulfillmentSpan: {
      fontWeight: 'normal',
      fontSize: '30px',
      lineHeight: '64px',
      letterSpacing: '0.015em',
      color: theme.palette.common.black,
      marginLeft: '5px',
    },
    meshifyTextWrapper: {
      marginTop: '30%',
    },
    welcomeText: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '32px',
      color: theme.palette.common.black,
      marginTop: '10%',
    },
    submitBtn: {
      marginTop: '8px',
    },
    loginText: {
      fontSize: '14px',
      lineHeight: '24px',
      color: theme.palette.common.black,
    },
    smallTopMargin: {
      marginTop: '5%',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
