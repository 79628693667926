import { useEffect } from 'react'
import { Prompt, PromptProps } from 'react-router'

const PromptOnLeave = ({ when, message }: PromptProps) => {
  useEffect(() => {
    if (!when) return

    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = message
      return message
    }

    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [when])

  return <Prompt when={when} message={message} />
}

export default PromptOnLeave
