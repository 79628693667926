import { InputAdornment } from '@mui/material'
import FormHelperText from '@mui/material/FormHelperText'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { Field, FieldProps, getIn } from 'formik'

import useTextFieldStyles from './text-field.styles'

type IProps = {
  name: string
  label: string
  required?: boolean
  helperText?: string
  validate?: (text: string) => string | Promise<void> | undefined
  initialized?: boolean
  disabled?: boolean
  hasEmptyOption?: boolean // indicates if dropdown will have a first empty option in list of items, showing that something must be selected
  endAdornment?: any // endAdornment component, if it's needed to be displayed in input - usually icon or a symbol at the end of input field
} & TextFieldProps

const FormikTextField = (props: IProps) => {
  const { name, label, helperText, required, initialized, disabled, hasEmptyOption, endAdornment, ...rest } = props

  const classes = useTextFieldStyles()

  const renderTextField = ({ field, form }: FieldProps<any>) => {
    const error = getIn(form.errors, name)
    const touched = getIn(form.touched, name)

    return (
      <>
        <TextField
          fullWidth={true}
          {...rest}
          {...field}
          error={Boolean(touched && error) || Boolean(initialized && error)}
          inputProps={{
            id: `${name}-textfield`,
            'data-testid': `${name}-textfield`,
            'aria-labelledby': `${name}-textfield-label`,
            'aria-label': label,
          }}
          InputProps={{
            endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment()}</InputAdornment> : undefined,
          }}
          SelectProps={{
            native: true,
            id: `${name}-textfield-select`,
            'aria-labelledby': `${name}-textfield-label`,
            'aria-label': label,
          }}
          InputLabelProps={{
            shrink: true, // needs to always be true, otherwise date type input label looks ugly
            htmlFor: `${name}-textfield`,
            id: `${name}-textfield-label`,
          }}
          label={`${label}${required === true ? '*' : ''}`}
          margin="normal"
          disabled={disabled}
        >
          {/* John and Katya had discussion about those 2 lines below
          This is not ideal solution, but we don't remember in what form the line below
          was meant to use, so we had to keep it to make sure previous work won't get broken
          and also had to add line afterwards */}
          {/* if dropdown field not required, we want to be able to have first empty <option /> element on a list */}
          {props.required !== true && props.select && <option />}
          {/* also have a way for required dropdowns to have an empty <option /> element, if needed */}
          {props.required === true && props.select && hasEmptyOption && <option />}
          {props.children}
        </TextField>

        {
          <FormHelperText data-testid="component-error-text" className={error && touched ? classes.errorText : classes.helperText} data-cy="requiredText">
            {error && touched ? error : helperText}
          </FormHelperText>
        }
      </>
    )
  }

  return <Field name={name}>{renderTextField}</Field>
}

export default FormikTextField
