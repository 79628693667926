import { Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import useScannerCapture from '../../utils/custom-hooks/use-scanner-capture'
import { parseScannedOrderItem } from '../../utils/helper-functions'
import useStyles from './index.styles'
import LeakAndFreezeCard from './leak-and-freeze-card'

interface ScanInformation {
  barcode: string
  sameAsLastScan: number
  invalid: boolean
  key: number
}

const DeviceInfo = () => {
  const scannerHistory = useScannerCapture()
  const [scans, setScans] = useState<ScanInformation[]>([])
  const classes = useStyles()

  useEffect(() => {
    const currentScan = scannerHistory.slice(-1)[0]
    if (currentScan) {
      const barcodeObject = parseScannedOrderItem(currentScan)

      const lastScan = scans.length > 0 ? scans[0] : undefined
      const duplicateScan = lastScan?.barcode === currentScan

      if (barcodeObject?.type === 'leakAndFreeze') {
        if (duplicateScan) {
          // increment the sameAsLastScan counter if there is a duplicate scan
          setScans(scans.map((scan, idx) => ({ ...scan, sameAsLastScan: scan.sameAsLastScan + (idx === 0 ? 1 : 0) })))
        } else {
          setScans([{ barcode: currentScan, sameAsLastScan: 0, invalid: false, key: scans.length }, ...scans])
        }
      } else {
        setScans([{ barcode: currentScan, sameAsLastScan: 0, invalid: true, key: scans.length }, ...scans])
      }
    }
  }, [scannerHistory])

  return (
    <div>
      <Typography variant="h4">{scans.length > 0 ? 'Scanned Items:' : 'Start scanning!'}</Typography>
      {scans?.map((scan) =>
        scan.invalid ? (
          <Paper key={scan.key} className={classes.unknownPaper}>
            I don't know what device that is!
          </Paper>
        ) : (
          <LeakAndFreezeCard key={scan.key} barcode={scan.barcode} sameAsLastScan={scan.sameAsLastScan} />
        )
      )}
    </div>
  )
}

export default DeviceInfo
