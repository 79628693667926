import { Grid, Hidden } from '@mui/material'

import LoginFooter from './login-footer'
import useLoginStyles from './login.styles'

const LoginContent = () => {
  const classes = useLoginStyles()

  return (
    <>
      <Hidden only={['xs', 'sm']}>
        <Grid item={true} xs={true} sm={true} md={true} lg={true} xl={true}>
          <div className={classes.loginImage}>
            <LoginFooter />
          </div>
        </Grid>
      </Hidden>

      <Hidden only={['md', 'lg', 'xl']}>
        <LoginFooter />
      </Hidden>
    </>
  )
}

export default LoginContent
