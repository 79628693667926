import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import LeakAndFreezeImg from '../../images/devices/leak-freeze.png'
import Api from '../../utils/api'
import { getAdminUrl, parseScannedOrderItem } from '../../utils/helper-functions'
import useStyles from './index.styles'

interface IATTData {
  iccid: string
  imsi: string
  'Rate Plan': string
  'Comm. Plan': string
  Status: string
  Activated: string
  Updated: string
  Shipped: string
}

interface ICarbonData {
  'Node Id': string
  Vanity: string
  Active: string
  'Folder Name': string
  folderId: number
  Created: string
  Updated: string
}

interface IProps {
  barcode: string
  sameAsLastScan: number
}

const LeakAndFreezeCard = ({ barcode, sameAsLastScan }: IProps) => {
  const classes = useStyles()

  const [attData, setAttData] = useState<IATTData | 'error' | undefined>(undefined)
  const [carbonData, setCarbonData] = useState<ICarbonData | 404 | undefined>(undefined)
  const [shortCode, setShortCode] = useState<string | undefined>(undefined)

  const barcodeObject = parseScannedOrderItem(barcode) as LeakAndFreezeBarcodeObject

  const fetchATT = async (iccid: string) => {
    try {
      const res = await fetch(`https://modj6tf4m0.execute-api.us-east-1.amazonaws.com/prod?iccid=${iccid}`, {
        headers: { Authorization: process.env.REACT_APP_ATT_AUTH_KEY as string },
      })
      const resBody = await res.json()

      if (res.ok && resBody) {
        setAttData({
          iccid: resBody.iccid,
          imsi: resBody.imsi,
          'Rate Plan': resBody.ratePlan,
          'Comm. Plan': resBody.communicationPlan,
          Status: resBody.status,
          Activated: resBody.dateActivated,
          Updated: resBody.dateUpdated,
          Shipped: resBody.dateShipped,
        })
      }
    } catch (e) {
      setAttData('error')
    }
  }

  useEffect(() => {
    if (!barcodeObject || !barcodeObject.iccid) {
      return
    }

    const fetchCarbon = async () => {
      // find node by uniqueId <=> imei
      const res = await Api.post('/api/nodes/query', {
        detail: true,
        filters: [
          {
            fieldName: 'uniqueId',
            operator: 'eq',
            value: barcodeObject.imei.toLowerCase(),
          },
        ],
        type: 'or',
      })

      if (res.ok && res.body.results.length > 0) {
        const node = res.body.results[0]
        setCarbonData({
          'Node Id': node.id,
          Vanity: node.vanity,
          Active: node.isActive ? 'true' : 'false',
          'Folder Name': node.folderName,
          folderId: node.folderId,
          Created: node.createdAt,
          Updated: node.updatedAt,
        })
      } else if (res.ok && res.body.results.length === 0) {
        setCarbonData(404)
      }
    }

    const fetchShortCode = async () => {
      const res = await Api.post('/api/hwhash/encode', {
        uniqueId: barcodeObject.imei,
        hashType: 'nbiotfull',
      })

      if (res.ok) {
        setShortCode(res.body.hashId)
      }
    }

    fetchATT(barcodeObject.iccid)
    fetchCarbon()
    fetchShortCode()
  }, [barcodeObject.iccid])

  if (!barcodeObject) {
    return <Box>not a valid barcode</Box>
  }

  return (
    <Paper className={classes.devicePaper} elevation={2}>
      <Box className={classes.deviceImgBox}>
        {sameAsLastScan > 0 && (
          <Typography variant="subtitle1" color="white" className={classes.duplicateScanText}>
            Duplicate scan {sameAsLastScan > 1 ? ` x${sameAsLastScan}` : ''}
          </Typography>
        )}
        <img src={LeakAndFreezeImg} height="160" width="160" />
      </Box>
      <Grid container={true}>
        <Grid item={true} sm={12} md={12} lg={6} xl={3} className={classes.gridItem}>
          <Typography variant="h6">Barcode information</Typography>
          {Object.keys(barcodeObject).map((key: string) => (
            <Box className={classes.flex} key={key}>
              <Typography variant="body2" component="span" className={classes.labelKeyItem}>
                <b>{key}</b>:
              </Typography>
              <Typography variant="body2">{barcodeObject[key as keyof LeakAndFreezeBarcodeObject]}</Typography>
            </Box>
          ))}
        </Grid>
        {attData && (
          <Grid item={true} sm={12} md={12} lg={6} xl={3} className={classes.gridItem}>
            <Typography variant="h6">AT&T Information</Typography>
            {attData === 'error' ? (
              <>
                <Typography variant="body2">AT&T failed to fetch, retry?</Typography>
                <Button onClick={() => fetchATT(barcodeObject.iccid)} variant="contained">
                  retry
                </Button>
              </>
            ) : (
              Object.keys(attData).map((key: string) => (
                <Box className={classes.flex} key={key}>
                  <Typography variant="body2" component="span" className={classes.labelKeyItem}>
                    <b>{key}</b>:
                  </Typography>
                  <Typography variant="body2">{attData[key as keyof IATTData]}</Typography>
                </Box>
              ))
            )}
          </Grid>
        )}
        {carbonData && (
          <Grid item={true} sm={12} md={12} lg={6} xl={3} className={classes.gridItem}>
            <Typography variant="h6">Carbon Information</Typography>
            {carbonData === 404 ? (
              <Typography variant="body2">Not found within carbon</Typography>
            ) : (
              Object.keys(carbonData).map((key: string) => {
                if (key === 'folderId') return null

                return (
                  <Box className={classes.flex} key={key}>
                    <Typography variant="body2" component="span" className={classes.labelKeyItem}>
                      <b>{key}</b>:
                    </Typography>
                    {key === 'Folder Name' ? (
                      <Typography variant="body2">
                        <a href={`${getAdminUrl()}/folders/${carbonData.folderId}`} target="_blank">
                          {carbonData[key as keyof ICarbonData]}
                        </a>
                      </Typography>
                    ) : key === 'Node Id' ? (
                      <Typography variant="body2">
                        <a href={`${getAdminUrl()}/nodes/${carbonData[key as keyof ICarbonData]}`} target="_blank">
                          {carbonData[key as keyof ICarbonData]}
                        </a>
                      </Typography>
                    ) : (
                      <Typography variant="body2">{carbonData[key as keyof ICarbonData]}</Typography>
                    )}
                  </Box>
                )
              })
            )}
          </Grid>
        )}
        {shortCode && (
          <Grid item={true} sm={12} md={12} lg={6} xl={3} className={classes.gridItem}>
            <Typography variant="h6">Activation Code</Typography>
            <Typography variant="h4">{shortCode}</Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default LeakAndFreezeCard
