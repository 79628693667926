import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Button, Grid, IconButton } from '@mui/material'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as Yup from 'yup'

import FormikTextField from '../../components/formik-textfield'
import Api from '../../utils/api'
import LoginContent from '../login/login-content'
import useLoginStyles from '../login/login.styles'

interface IResetFormValues {
  newPassword: string
  confirmPassword: string
}

const ResetPassword = () => {
  const loginClasses = useLoginStyles()

  const history = useHistory()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)

  const schema = Yup.object().shape({
    newPassword: Yup.string().required('required').min(8, 'Password must be at least 8 characters').max(100, 'Password must be less than 100 characters.'),
    confirmPassword: Yup.string()
      .required('required')
      .oneOf([Yup.ref('newPassword')], 'Password does not match'),
  })

  const initialValues: IResetFormValues = {
    newPassword: '',
    confirmPassword: '',
  }

  const resetPassword = async (values: IResetFormValues, helpers: FormikHelpers<IResetFormValues>) => {
    helpers.setSubmitting(true)
    const resetToken = new URLSearchParams(location.search).get('ref')
    helpers.setSubmitting(false)

    const res = await Api.put(`/api/passwords/changepasswordwithtoken`, {
      newPassword: values.newPassword,
      token: resetToken,
    })

    if (res.ok) {
      history.push('/')
      enqueueSnackbar('Your password has been successfully changed. You can now login with your new password', { variant: 'success' })
    } else {
      enqueueSnackbar(
        res.body && res.body.detail !== ''
          ? `An error occured and we did not reset the password: ${res.body.detail}`
          : 'Something went wrong and we could not reset your password. Please try again later',
        { variant: 'error' }
      )
    }
  }

  const newPasswordEndAdornment = () => (
    <IconButton aria-label="toggle new password visibility" onClick={() => setShowNewPassword(!showNewPassword)} data-testid="show-new-password" size="large">
      {showNewPassword ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  )

  const confirmPasswordEndAdornment = () => (
    <IconButton
      aria-label="toggle confirm password visibility"
      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
      data-testid="show-confirm-password"
      size="large"
    >
      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  )

  return (
    <Grid container={true}>
      <Grid item={true} xs={12} sm={12} md={6} lg={5} xl={5}>
        <Grid container={true} direction="row" justifyContent="center" alignItems="center">
          <Grid item={true} xs={8}>
            <div className={loginClasses.meshifyTextWrapper}>
              <span className={loginClasses.meshifySpan}>Meshify</span>
              <span className={loginClasses.fulfillmentSpan}>Fulfillment</span>
            </div>
          </Grid>

          <Grid item={true} xs={8}>
            <p className={loginClasses.welcomeText}>Reset your password</p>
          </Grid>

          <Grid item={true} xs={8}>
            <Formik initialValues={initialValues} onSubmit={resetPassword} validationSchema={schema}>
              {({ isValid, isSubmitting }: FormikProps<IResetFormValues>) => {
                return (
                  <Form className={loginClasses.smallTopMargin}>
                    <Grid container={true} spacing={2}>
                      <Grid item={true} xs={12} data-cy="username">
                        <FormikTextField
                          name="newPassword"
                          label="New Password"
                          required={true}
                          variant="outlined"
                          size="small"
                          type={showNewPassword ? 'text' : 'password'}
                          endAdornment={newPasswordEndAdornment}
                        />
                      </Grid>

                      <Grid item={true} xs={12} data-cy="password">
                        <FormikTextField
                          name="confirmPassword"
                          label="Confirm Password"
                          required={true}
                          variant="outlined"
                          size="small"
                          type={showConfirmPassword ? 'text' : 'password'}
                          endAdornment={confirmPasswordEndAdornment}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <Button
                          variant="contained"
                          data-testid="reset-btn"
                          color="primary"
                          type="submit"
                          disabled={!isValid || isSubmitting}
                          fullWidth={true}
                          className={loginClasses.submitBtn}
                        >
                          Reset Password
                        </Button>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <Grid container={true} justifyContent="flex-end">
                          <Button data-testid="forgotPwBtn" onClick={() => history.push('/')} color="primary">
                            Go back to Login
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                )
              }}
            </Formik>
          </Grid>
        </Grid>
      </Grid>

      <LoginContent />
    </Grid>
  )
}

export default ResetPassword
