const headers = {
  credentials: 'include',
}

const get = async (url: string, args?: Record<string, unknown>) => {
  const res = await fetch(url, { headers, ...args })

  let resBody
  try {
    resBody = await res.json()
  } catch (e) {
    // sometimes GET requests dont return any payload - i.e. GET /api/forgot-password
    resBody = {
      detail: e,
    }
  }

  const returnObject = {
    status: res.status,
    statusText: res.statusText,
    ok: res.ok,
    body: resBody,
  }

  return returnObject
}

const post = async (url: string, body: Record<string, unknown>, args?: Record<string, unknown>) => {
  let res
  try {
    res = await fetch(url, {
      headers,
      method: 'POST',
      body: JSON.stringify(body),
      ...args,
    })
  } catch (e) {
    return {
      ok: false,
      body: {} as any,
      status: 500,
      statusText: (e as any).message,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      aborted: res?.request?.signal?.aborted || e.message === 'The user aborted a request.',
    }
  }

  let resBody
  try {
    resBody = await res.json()
  } catch (e) {
    if (!res.ok) {
      resBody = {
        detail: e,
      }
    }
  }

  const returnObject = {
    status: res.status,
    statusText: res.statusText,
    ok: res.ok,
    body: resBody,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    aborted: res?.request?.signal?.aborted,
  }

  return returnObject
}

const put = async (url: string, body: Record<string, unknown>, args?: Record<string, unknown>) => {
  const res = await fetch(url, {
    headers,
    method: 'PUT',
    body: JSON.stringify(body),
    ...args,
  })

  let resBody
  try {
    resBody = await res.json()
  } catch (e) {
    if (!res.ok) {
      resBody = {
        detail: e,
      }
    }
  }

  const returnObject = {
    status: res.status,
    statusText: res.statusText,
    ok: res.ok,
    body: resBody,
  }

  return returnObject
}

const del = async (url: string) => {
  const res = await fetch(url, {
    headers,
    method: 'DELETE',
  })

  let resBody
  try {
    resBody = await res.json()
  } catch (e) {
    if (!res.ok) {
      resBody = {
        detail: e,
      }
    }
  }

  const returnObject = {
    status: res.status,
    statusText: res.statusText,
    ok: res.ok,
    body: resBody,
  }

  return returnObject
}

const apiMethods = {
  get,
  post,
  put,
  del,
}

export default apiMethods
