import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import * as Sentry from '@sentry/browser'
import { Component, ReactNode } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import brokenView from '../../images/error-page-img.svg'
import { styles } from './main.styles'

interface IErrorBoundaryState {
  hasError: boolean
  error: { message: string }
}

type IErrorBoundaryProps = WithStyles<typeof styles> & {
  children: ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & RouteComponentProps<any>

class MainWithErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  public static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    // Can add error interpreters for common errors here.
    return { hasError: true, error }
  }

  public readonly state = {
    hasError: false,
    error: { message: '' },
  }

  constructor(props: IErrorBoundaryProps) {
    super(props)
    const { history } = this.props

    history.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        })
      }
    })
  }

  public componentDidCatch(error: Error, info: any) {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.withScope((scope) => {
        Object.keys(info).forEach((key) => {
          scope.setExtra(key, info[key])
        })
        Sentry.captureException(error)
      })
    }
  }

  public render() {
    const { classes } = this.props

    if (this.state.hasError && this.state.error != null) {
      const { error } = this.state

      return (
        <main className={classes.mainContentWrapper}>
          <Grid alignContent="center" justifyContent="center" container={true}>
            <Grid className={classes.messageGridItem} item={true} xs={7}>
              <img alt="Something went wrong" src={brokenView} className={classes.errorImg} />

              <Typography align="center" variant="h5">
                Something went wrong, but we have tracked the error.
              </Typography>

              <div className={classes.divider} />

              <Typography align="center" variant="subtitle1">
                The error provided the following message:
              </Typography>

              <Typography color="error" align="center" variant="subtitle1">
                {error != null && error.message}
              </Typography>

              <div className={classes.divider} />

              <Typography align="center" color="textSecondary">
                For help, contact support@meshify.com
              </Typography>
            </Grid>
          </Grid>
        </main>
      )
    }

    return <main className={classes.mainContentWrapper}>{this.props.children}</main>
  }
}

export default withRouter(withStyles(styles)(MainWithErrorBoundary))
