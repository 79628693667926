export const AUTH_TOKEN = 'fulfillmentToken'
export const AUTH_TOKEN_VALUE = 'meshify-authenticated'

export const PRIVACY_POLICY_LINK = 'https://hsbprod.carbon.meshify.com/api/files/public//docs/privacypolicy.pdf'
export const TERMS_OF_USE_LINK = 'https://hsbprod.carbon.meshify.com/api/files/public/legal/termsofuse.pdf'

export const SCANNER_CODES = {
  START_FULFILLMENT: 'job:fulfillment',
  START_RETURN: 'job:return',
  COMMAND_CONFIRM: 'command:confirm',
  FINISH_RETURN: 'status:discrepant',
  FINISH_RETURN_CONFIRM: 'status:discrepantconfirm',
  COMMAND_ROUTE_HOME: 'goto:dashboard',
}

export const FAILED_SIGNUP_KEY = 'failed-signup-key'

export const BULK_ITEM_REMEMBER = 'bulk-item-remember-'

export const SCROLL_ON_SCAN = 'scroll-on-scan'

export const SORT_ITEMS = 'sort-items'
