import Dashboard from './dashboard'
import DeliveredOrders from './delivered-orders'
import DeviceInfo from './device-info'
import FulfilledOrders from './fulfilled-orders'
import Login from './login'
import OrderWrapper from './order/order-wrapper'
import Replacements from './replacements'
import ResetPassword from './reset-password'
import ReturnWrapper from './return/return-wrapper'
import ShippedOrders from './shipped-orders'
import SubmittedOrders from './submitted-orders'

export default {
  Dashboard,
  DeliveredOrders,
  DeviceInfo,
  FulfilledOrders,
  OrderWrapper,
  Login,
  Replacements,
  ResetPassword,
  ReturnWrapper,
  ShippedOrders,
  SubmittedOrders,
}
