import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = () =>
  createStyles({
    mainContentWrapper: {
      padding: '20px 15px',
    },
    layoutWrapper: {
      minHeight: '100vh',
      display: 'flex',
    },
    mainItemsWrapper: {
      flex: '1 1 auto',
      display: 'flex',
      height: 'inherit',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
