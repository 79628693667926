import { Toolbar } from '@mui/material'
import { Suspense, useContext } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import useAppStyles from './App.styles'
import DeveloperArea from './components/dev-area'
import Header from './components/header'
import MainWithErrorBoundary from './components/main-with-error-boundary'
import Sidenav from './components/sidenav'
import { AuthenticationContext } from './providers'
import Routes from './routes'

const PrivateRouteWrapper: React.FC = ({ children }) => {
  const classes = useAppStyles()

  return (
    <div className={classes.layoutWrapper}>
      <Header />
      <Sidenav />
      <div className={classes.mainItemsWrapper}>
        <MainWithErrorBoundary>
          <Toolbar />
          {children}
        </MainWithErrorBoundary>
        <DeveloperArea />
      </div>
    </div>
  )
}

const App = () => {
  const { isAuthenticated } = useContext(AuthenticationContext)
  const location = useLocation()

  return (
    <div>
      <Suspense fallback={<div />}>
        {isAuthenticated === false ? (
          <Switch>
            <Route path="/" exact={true} component={Routes.Login} />
            <Route path="/reset-password" component={() => <Routes.ResetPassword />} />
            <Redirect from="*" to="/" />
          </Switch>
        ) : (
          <PrivateRouteWrapper>
            <Switch>
              <Route path="/dashboard" exact={true} component={Routes.Dashboard} />
              <Route path="/order/:orderId" exact={true} component={Routes.OrderWrapper} />
              <Route path="/return/:returnId" exact={true} component={Routes.ReturnWrapper} />
              <Route path="/submitted-orders" exact={true} component={Routes.SubmittedOrders} />
              <Route path="/fulfilled-orders" exact={true} component={Routes.FulfilledOrders} />
              <Route path="/shipped-orders" exact={true} component={Routes.ShippedOrders} />
              <Route path="/delivered-orders" exact={true} component={Routes.DeliveredOrders} />
              <Route path="/replacements" exact={true} component={Routes.Replacements} />
              <Route path="/device-info" exact={true} component={Routes.DeviceInfo} />
              <Redirect to={{ pathname: '/dashboard', state: { from: location } }} />
            </Switch>
          </PrivateRouteWrapper>
        )}
      </Suspense>
    </div>
  )
}

export default App
