import fetchIntercept from 'fetch-intercept'
import { createContext, Dispatch, SetStateAction, useLayoutEffect, useState } from 'react'

import api from '../utils/api'
import { AUTH_TOKEN, AUTH_TOKEN_VALUE } from '../utils/constants'

export const AuthenticationContext = createContext<{
  ownUser: UserType | undefined
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>
  isAuthenticated: boolean
}>({
  ownUser: undefined,
  setIsAuthenticated: () => null,
  isAuthenticated: false,
})

export const AuthProvider: React.FC = ({ children }) => {
  const [ownUser, setOwnUser] = useState<UserType | undefined>(undefined)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(localStorage.getItem(AUTH_TOKEN) === AUTH_TOKEN_VALUE)

  useLayoutEffect(() => {
    let isSubscribed = true

    const unregister = fetchIntercept.register({
      response(response) {
        if (response.status && response.status === 401) {
          setIsAuthenticated(false)
          localStorage.setItem(AUTH_TOKEN, '')
        }
        return response
      },
    })
    const getOwnUser = async () => {
      try {
        const user = await api.get('/api/users/me')
        if (isSubscribed) {
          if (user.ok) {
            setOwnUser(user.body)

            setIsAuthenticated(true)
            localStorage.setItem(AUTH_TOKEN, AUTH_TOKEN_VALUE)
          } else {
            setIsAuthenticated(false)
            localStorage.setItem(AUTH_TOKEN, '')
          }
        }
      } catch (e) {
        if (isSubscribed) {
          setIsAuthenticated(false)
          localStorage.setItem(AUTH_TOKEN, '')
        }
      }
    }

    if (isAuthenticated && ownUser == null) {
      getOwnUser()
    }

    return () => {
      unregister()
      isSubscribed = false
    }
  }, [isAuthenticated, ownUser])
  return <AuthenticationContext.Provider value={{ ownUser, setIsAuthenticated, isAuthenticated }}>{children}</AuthenticationContext.Provider>
}

export default AuthProvider
