import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/system'

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
