import { useEffect, useMemo, useReducer } from 'react'

interface ITableState {
  loading: boolean
  data: React.ReactNode[][] | null
  error: { message: string } | null
}

type FetchType = () => Promise<{
  data: React.ReactNode[][] | any[]
  errorMessage?: string | null
}>

type ActionType = { type: 'toggleLoading' } | { type: 'setData'; payload: React.ReactNode[][] } | { type: 'setError'; payload: { message: string } | null }

export const useTableState = (fetch: FetchType) => {
  const initialState: ITableState = { loading: false, data: null, error: null }

  const reducer = (state: ITableState, action: ActionType) => {
    switch (action.type) {
      case 'toggleLoading':
        return { ...state, loading: true }
      case 'setData':
        return { ...state, data: action.payload, loading: false }
      case 'setError':
        return { ...state, error: action.payload, loading: false }
      default:
        throw Error('Action type not supported')
    }
  }

  const [tableState, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    let isSubscribed = true
    dispatch({ type: 'toggleLoading' })

    const updateTableView = () => {
      fetch()
        .then((res) => {
          const { data, /*setEntities,*/ errorMessage } = res

          if (isSubscribed) {
            if (data) {
              dispatch({ type: 'setData', payload: data })
            }

            // we need to set error to null right away, otherwise if error was previously set it persists for some reason
            dispatch({ type: 'setError', payload: null })

            if (errorMessage && data.length === 0) {
              dispatch({ type: 'setError', payload: { message: errorMessage } })
            }
          }
        })
        .catch((error: string) => {
          if (isSubscribed) {
            dispatch({ type: 'setError', payload: { message: error ?? 'Something went wrong' } })
          }
        })
    }

    updateTableView()

    return () => {
      isSubscribed = false
    }
  }, [fetch])

  return useMemo(() => tableState, [tableState])
}
