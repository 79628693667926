import Api from './api'

const sendLog = async (nodeId: number, action: string, message: any, retries = 3) => {
  if (retries < 0) return Promise.resolve()

  try {
    const res = await Api.post('/api/publish/cloud', {
      channelName: 'log',
      nodeId,
      value: { action, message },
    })

    if (!res.ok) throw Error('retry')
    // if successful, wait at least a second before sending another message via logs
    await new Promise((resolve) => setTimeout(resolve, 1000))
  } catch (e) {
    // if we failed, try again and await until it returns
    await new Promise((resolve) =>
      setTimeout(async () => {
        await sendLog(nodeId, action, message, retries - 1)
        resolve(true)
      }, 500)
    )
  }
}

const log = (() => {
  let pending = Promise.resolve()

  const run = async (nodeId: number, action: string, message: any) => {
    // await the last request
    await pending

    // send the next request
    return sendLog(nodeId, action, message)
  }

  // update pending promise so that next task could await for it
  return (nodeId: number, action: string, message: any) => (pending = run(nodeId, action, message))
})()

export default { log }
