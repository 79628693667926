import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Button, Grid, IconButton } from '@mui/material'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router'
import * as Yup from 'yup'

import FormikTextField from '../../components/formik-textfield'
import { AuthenticationContext } from '../../providers'
import Api from '../../utils/api'
import { AUTH_TOKEN, AUTH_TOKEN_VALUE } from '../../utils/constants'
import ForgotPasswordModal from './forgot-password-modal'
import LoginContent from './login-content'
import useLoginStyles from './login.styles'

interface ILoginValues {
  username: string
  password: string
}

const Login = () => {
  const history = useHistory()
  const classes = useLoginStyles()

  const { enqueueSnackbar } = useSnackbar()
  const { setIsAuthenticated } = useContext(AuthenticationContext)

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState<boolean>(false)

  const schema = Yup.object().shape({
    username: Yup.string().required(' ').email('Must be a valid email'),
    password: Yup.string().required(' '),
  })

  const initialValues = {
    username: '',
    password: '',
  }

  const loginUser = async (values: ILoginValues, actions: FormikHelpers<ILoginValues>) => {
    const { username, password } = values

    try {
      const res = await Api.post('/api/login', {
        username,
        password,
      })

      if (!res.ok) {
        actions.resetForm()
        enqueueSnackbar(res.body.detail ?? 'Something went wrong. Please try again', { variant: 'error' })
      } else {
        setIsAuthenticated(true)
        localStorage.setItem(AUTH_TOKEN, AUTH_TOKEN_VALUE)
        history.push('/dashboard')
      }
    } catch ({ message }) {
      setIsAuthenticated(false)
      localStorage.removeItem(AUTH_TOKEN)
      enqueueSnackbar(`Something went wrong. ${message}`, { variant: 'error' })
    }
  }

  const endAdornment = () => (
    <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)} data-testid="show-password" size="large">
      {showPassword ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  )

  return (
    <Grid container={true}>
      <Grid item={true} xs={12} sm={12} md={6} lg={5} xl={5}>
        <Grid container={true} direction="row" justifyContent="center" alignItems="center">
          <Grid item={true} xs={8}>
            <div className={classes.meshifyTextWrapper}>
              <span className={classes.meshifySpan}>Meshify</span>
              <span className={classes.fulfillmentSpan}>Fulfillment</span>
            </div>
          </Grid>

          <Grid item={true} xs={8}>
            <p className={classes.welcomeText}>Welcome</p>
          </Grid>

          <Grid item={true} xs={8}>
            <span className={classes.loginText} data-cy="loginPageText">Log in to your account</span>
          </Grid>

          <Grid item={true} xs={8}>
            <Formik initialValues={initialValues} onSubmit={loginUser} validationSchema={schema}>
              {(formikBag: FormikProps<ILoginValues>) => {
                const { isValid } = formikBag

                return (
                  <Form className={classes.smallTopMargin}>
                    <Grid container={true} spacing={2}>
                      <Grid item={true} xs={12} data-cy="username">
                        <FormikTextField name="username" label="Email" required={true} variant="outlined" size="small" />
                      </Grid>

                      <Grid item={true} xs={12} data-cy="password">
                        <FormikTextField
                          autoComplete="current-password"
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          label="Password"
                          required={true}
                          variant="outlined"
                          endAdornment={endAdornment}
                          size="small"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <Button
                          data-cy="loginButton"
                          variant="contained"
                          data-testid="signinBtn"
                          color="primary"
                          type="submit"
                          disabled={!isValid}
                          fullWidth={true}
                          size="large"
                          className={classes.submitBtn}
                        >
                          Log In
                        </Button>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <Grid container={true} justifyContent="center">
                          <Button data-testid="forgotPwBtn" onClick={() => setForgotPasswordOpen(true)} color="primary">
                            Forgot Password?
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                )
              }}
            </Formik>
          </Grid>
        </Grid>
      </Grid>

      <LoginContent />

      {forgotPasswordOpen && <ForgotPasswordModal open={forgotPasswordOpen} handleClose={setForgotPasswordOpen} />}
    </Grid>
  )
}

export default Login
