import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const styles = () =>
  createStyles({
    dialogActions: {
      padding: '16px 24px',
    },
  })

const useStyles = makeStyles(styles)
export default useStyles
