import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Form, Formik, FormikProps } from 'formik'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'

import FormikTextField from '../../../components/formik-textfield'
import Api from '../../../utils/api'
import useStyles from './index.styles'

interface IProps {
  open: boolean
  handleClose: (state: boolean) => void
}

interface IForgotPasswordValues {
  email: string
}

const ForgotPasswordModal = (props: IProps) => {
  const { open, handleClose } = props

  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const schema = Yup.object().shape({
    email: Yup.string().required('Required').email('Must be a valid email address'),
  })

  const handleForgotPassword = async (values: IForgotPasswordValues) => {
    try {
      await Api.get(`/api/passwords/resetpassword?email=${encodeURIComponent(values.email)}&refererOption=${window.location.href}`)
      enqueueSnackbar('If a user with provided email exists in our system, a password reset email will be sent', { variant: 'success' })
    } catch (err) {
      enqueueSnackbar('We can not send a reset password link at this moment.', { variant: 'error' })
    } finally {
      handleClose(false)
    }
  }

  return (
    <Dialog open={open} onClose={() => handleClose(false)} aria-labelledby="alert-dialog-title" maxWidth="md">
      <DialogTitle>
        <Typography variant="h5" component="div" className={classes.dialogTitle}>
          Forgot Password?
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose(false)} data-testid="close-btn" size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>Enter the email address associated with your account, and we'll email you a link to reset your password.</DialogContentText>

        <Formik initialValues={{ email: '' }} onSubmit={handleForgotPassword} validationSchema={schema} initialErrors={{ email: ' ' }}>
          {({ isValid, isSubmitting }: FormikProps<IForgotPasswordValues>) => {
            return (
              <Form>
                <Grid container={true}>
                  <Grid item={true} xs={12}>
                    <FormikTextField name="email" label="Email Address" required={true} type="email" />
                  </Grid>

                  <Grid item={true} xs={12} container={true} justifyContent="center" className={classes.forgotBtn}>
                    <Button variant="contained" color="primary" data-testid="forgotPasswordBtn" type="submit" disabled={!isValid || isSubmitting}>
                      Send Instructions
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default ForgotPasswordModal
