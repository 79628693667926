import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material'

import CRLFBarcode from '../../images/crlf-barcode.png'
import VolumeBarcodes from '../../images/volume-barcodes.png'
import useStyles from './scanner-settings-dialog.styles'

interface IProps {
  onClose: () => void
}

const ScannerSettingsDialog = ({ onClose }: IProps) => {
  const classes = useStyles()

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogTitle>Adjust scanner settings</DialogTitle>
      <DialogContent>
        <DialogContentText gutterBottom={true}>
          If your scanner is not automatically continuing after scanning in the Start Fulfillment or Start Return pop-ups, you could try scanning this barcode
          to fix the problem
        </DialogContentText>
        <Stack justifyContent="center" alignItems="center">
          <img src={CRLFBarcode} alt="crlf-barcode" className={classes.crlfBarcode} />
        </Stack>
      </DialogContent>
      <DialogContent>
        <DialogContentText gutterBottom={true}>
          If you would like to change your the volume of your scanner, you can scan one of the barcodes below
        </DialogContentText>
        <Stack justifyContent="center" alignItems="center">
          <img src={VolumeBarcodes} alt="volume-barcodes" className={classes.volumeBarcodes} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ScannerSettingsDialog
