import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import Order, { ErrorTypes } from '.'
import PromptOnLeave from '../../components/prompt-on-leave'
import logger from '../../utils/logger'
import { getFolder, getLocationManager, getOrderChannelData, getOrderNode } from './order-helper'

const OrderWrapper = () => {
  const { orderId } = useParams<{ orderId: string }>()

  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const [order, setOrder] = useState<IOrderItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [contactLoading, setContactLoading] = useState<boolean>(true)
  const [error, setError] = useState<ErrorTypes | undefined>()

  // the id of the order node
  const [nodeId, setNodeId] = useState<number | undefined>(undefined)
  // the folderId that the order node is assigned to
  const [orderNodeFolderId, setOrderNodeFolderId] = useState<number | undefined>(undefined)
  // the folder that the order node is assigned to
  const [orderNodeFolder, setOrderNodeFolder] = useState<IFolderType | undefined>(undefined)
  // the location manager that the order will be shipped to
  const [locationManager, setLocationManager] = useState<IUserType | undefined>(undefined)
  // the address code of the folder, used in the signup keys.
  const [addressCode, setAddressCode] = useState<string | undefined>(undefined)
  // the short order number of the order that gets attached to the device nodes upon activation
  const [shortOrderNumber, setShortOrderNumber] = useState<number>(0)
  // indicator whether or not a device has been scanned yet, used to prevent page reloading/navigating
  const [deviceHasBeenScanned, setDeviceHasBeenScanned] = useState<boolean>(false)

  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true)

      // get the orderNode that is attached to the order number
      const orderNode = await getOrderNode(orderId)

      if (!orderNode.ok) {
        setError(orderNode.error)
        setLoading(false)
        enqueueSnackbar(
          orderNode.error === ErrorTypes.NOT_FOUND
            ? `Order ${orderId} was not found`
            : `Something went wrong, and we could not get order ${orderId}. Please try again later`,
          { variant: 'error' }
        )
        history.push('/dashboard')

        return
      }

      const orderNodeId = orderNode.body?.id
      // get the order
      const nodeOrder = await getOrderChannelData(orderNodeId)
      setLoading(false)

      if (!nodeOrder.ok) {
        setError(nodeOrder.error)
        enqueueSnackbar('Something went wrong, and your order node information did not load. Please try again', { variant: 'error' })
        logger.log(orderNodeId, 'Order Node channel data missing/failed to fetch', nodeOrder)
        history.push('/dashboard')
        return
      }

      const orderStatus = nodeOrder.body?.fulfillment_date?.value
      // if orderStatus already has a value, it has been fulfilled
      if (orderStatus) {
        setError(ErrorTypes.ALREADY_FULFILLED)
        enqueueSnackbar(`The order ${orderId} was already fulfilled`, { variant: 'info' })
        logger.log(orderNodeId, 'Order Node already fulfilled', orderStatus)
        history.push('/dashboard')
        return
      }

      const nodeShortOrderNumber = nodeOrder.body?.short_order_number?.value
      if (!nodeShortOrderNumber) {
        enqueueSnackbar('This order does not have a short order number, it cannot be fulfilled.', { variant: 'error' })
        logger.log(orderNodeId, 'Order Node has no short order number', nodeOrder.body)
        history.push('/dashboard')
        return
      }
      setShortOrderNumber(nodeShortOrderNumber)

      let orderItems = nodeOrder.body?.order_items?.value
      if (typeof orderItems === 'string') {
        try {
          orderItems = JSON.parse(orderItems)
        } catch (e) {
          enqueueSnackbar("This order's order_items could not be parsed correctly. Please let someone at Meshify know.", { variant: 'error' })
          logger.log(orderNodeId, 'order_items failed to be parsed', nodeOrder.body?.order_items)
          history.push('/dashboard')
          return
        }
      }

      // filter out unfulfillable items
      orderItems = orderItems.filter((item: IOrderItem) => item.fulfillable)

      // create a new list of items, one object for each individual
      // item, including duplicate items
      const allOrderItems: IOrderItem[] = []
      orderItems.forEach((item: IOrderItem) => {
        for (let i = 0; i < item.quantity; i++) {
          allOrderItems.push({
            ...item,
            quantity: 1,
            scanned: false,
            id: allOrderItems.length,
          })
        }
      })

      if (allOrderItems.length === 0) {
        enqueueSnackbar('This order has no items to fulfill. If you believe this is a mistake, please let someone at Meshify know.', { variant: 'error' })
        logger.log(orderNodeId, 'allOrderItems was empty', nodeOrder.body)
        history.push('/dashboard')
        return
      }

      setOrder(allOrderItems)
      setNodeId(orderNodeId)
      setOrderNodeFolderId(orderNode?.body?.folderId)
      setError(undefined)

      logger.log(orderNodeId, 'Order found successfully', { orderId, orderNodeId, folderId: orderNode?.body?.folderId })
    }

    fetchOrder()
  }, [orderId, enqueueSnackbar])

  useEffect(() => {
    if (!orderNodeFolderId) {
      return
    }

    const fetchFolder = async () => {
      const folderResponse = await getFolder(orderNodeFolderId)

      if (folderResponse.ok && folderResponse.body) {
        setOrderNodeFolder(folderResponse.body)
      } else {
        enqueueSnackbar(`Folder ID ${orderNodeFolderId} could not be found.`, { variant: 'error' })
        history.push('/dashboard')
        return
      }
    }

    fetchFolder()
  }, [orderNodeFolderId])

  useEffect(() => {
    if (!orderNodeFolder) {
      return
    }

    const fetchExtraInformation = async () => {
      setContactLoading(true)

      const addressCodeUnformatted = orderNodeFolder?.information?.address?.code
      const addressCodeFormatted = addressCodeUnformatted?.replace(/\s/g, '').toUpperCase().substring(0, 5)
      setAddressCode(addressCodeFormatted)

      const contact = await getLocationManager(orderNodeFolder.id)

      setLocationManager(contact)
      setContactLoading(false)
    }

    fetchExtraInformation()
  }, [orderNodeFolder])

  return (
    <>
      <PromptOnLeave when={deviceHasBeenScanned} message="Leaving this page will require the order to be reset. Please press cancel and finish the order." />
      <Order
        orderId={orderId}
        nodeId={nodeId as number}
        initialOrder={order}
        loading={loading}
        contactLoading={contactLoading}
        error={error}
        shortOrderNumber={shortOrderNumber}
        folderId={orderNodeFolderId}
        addressCode={addressCode}
        locationManager={locationManager}
        setDeviceHasBeenScanned={setDeviceHasBeenScanned}
      />
    </>
  )
}

export default OrderWrapper
