import { fireEvent } from '@testing-library/react'

import successSound from '../audio/affirmative.mp3'
import errorSound from '../audio/error.wav'
import twilioSound from '../audio/twilio.wav'

export const parseScannedOrderItem = (scanInput: string): BarcodeObject | undefined => {
  const newDeviceObject = parseSemicolonDelimitedBarcode(scanInput)
  if (newDeviceObject) return newDeviceObject

  const oldDeviceObject = parseLoraV2Barcode(scanInput)
  if (oldDeviceObject) return oldDeviceObject

  if (scanInput.toLowerCase().startsWith('sku:')) {
    // allow barcodes that are either in the form "sku:..." or "sku:...;something else"
    return { sku: scanInput.substring(4).split(';')[0], type: 'generic' } as GenericBarcodeObject
  }
}

// this is for devices that have a barcode format of "sku:...;model:..."
const parseSemicolonDelimitedBarcode = (
  scanInput: string
): GatewayBarcodeObject | LeakAndFreezeBarcodeObject | LoraV3BarcodeObject | CalyxBarcodeObject | undefined => {
  const inputSections = scanInput.split(';')
  const attributeMap: Record<string, string> = {}
  inputSections.forEach((section) => {
    const [key, value] = section.split(':', 2)
    attributeMap[key.toLowerCase()] = value
  })

  const calyxObject = isCalyxObject(attributeMap)
  if (calyxObject) return calyxObject

  const loraV3Object = isLoraV3Object(attributeMap)
  if (loraV3Object) return loraV3Object

  const leakAndFreezeObject = isLeakAndFreezeObject(attributeMap)
  if (leakAndFreezeObject) return leakAndFreezeObject

  const gatewayObject = isGatewayObject(attributeMap)
  if (gatewayObject) return gatewayObject

  return undefined
}

// this is for devices that have a barcode format of "...;...;...;..."
const parseLoraV2Barcode = (scanInput: string): LoraV2BarcodeObject | undefined => {
  const inputSections = scanInput.split(';')

  if (inputSections.length <= 1) {
    return undefined
  }

  const sn = inputSections?.[0]
  let sku = inputSections?.[1]
  const model = inputSections?.[2]
  const v2Tag = inputSections?.[3]

  const skuPrefix = sku.split('-')[0]
  const modelPrefix = model?.split('-')[0]
  if (skuPrefix === 'FL' || skuPrefix === 'FM') {
    switch (modelPrefix) {
      case 'MDUS':
        sku = 'MY-MD1'
        break
      case 'LDUS':
        sku = 'MY-LD1'
        break
      case 'TDUS':
        sku = 'MY-TD1'
        break
    }
  }

  if (!model) return undefined

  return {
    sn: sn.includes(':') ? sn.split(':')[1] : sn,
    sku,
    model,
    isV2: ['v2', 'v21'].includes(v2Tag?.toLowerCase()),
    type: 'lorav2',
  }
}

export const simulateScanner = (command: string) => {
  // if the command is too small then lets not emulate it
  if (command.length < 5) {
    return
  }

  for (const key of command) {
    // tests should use fireEvent
    if (!process.env.NODE_ENV) {
      fireEvent.keyDown(document, { key })
    } else {
      document.dispatchEvent(new KeyboardEvent('keydown', { key }))
    }
  }
}

const isLeakAndFreezeObject = (obj: Record<string, string>) => {
  const leakAndFreezeKeys = ['sku', 'model', 'sn', 'iccid', 'imei', 'commver']
  for (const key of leakAndFreezeKeys) {
    if (!obj[key.toLowerCase()]) {
      return undefined
    }
  }

  return { ...obj, type: 'leakAndFreeze' } as unknown as LeakAndFreezeBarcodeObject
}

const isLoraV3Object = (obj: Record<string, string>) => {
  const loraV3Keys = ['sc', 'cc', 'sku', 'model', 'sn']
  for (const key of loraV3Keys) {
    if (!obj[key.toLowerCase()]) {
      return undefined
    }
  }

  if (!['MYTEMPS2', 'MYLEAKS2'].includes(obj.sku)) {
    return undefined
  }

  return { ...obj, type: 'lorav3' } as unknown as LoraV3BarcodeObject
}

const isCalyxObject = (obj: Record<string, string>) => {
  const loraV3Keys = ['sc', 'cc', 'sku', 'model', 'sn']
  for (const key of loraV3Keys) {
    if (!obj[key.toLowerCase()]) {
      return undefined
    }
  }

  if (!['CXAMNAS1'].includes(obj.sku)) {
    return undefined
  }

  return { ...obj, type: 'calyx' } as unknown as CalyxBarcodeObject
}

const isGatewayObject = (obj: Record<string, string>) => {
  const gatewayKeys = ['sku', 'model', 'sn', 'iccid']
  for (const key of gatewayKeys) {
    if (!obj[key.toLowerCase()]) {
      return undefined
    }
  }

  return { ...obj, type: 'gateway' } as unknown as GatewayBarcodeObject
}

export const playSuccessSound = () => {
  const audio = new Audio(successSound)
  audio.play()
}

export const playErrorSound = () => {
  const audio = new Audio(errorSound)
  audio.play()
}

export const playTwilioSound = () => {
  const audio = new Audio(twilioSound)
  audio.play()
}

export const getFormattedDateTime = (date: Date) => {
  if (date.valueOf() === 0) {
    return undefined
  }

  const formattedDate = [date.getMonth() + 1, date.getDate(), date.getFullYear()].join('/')

  const formattedTime = [
    date.getHours().toString().padStart(2, '0'),
    date.getMinutes().toString().padStart(2, '0'),
    date.getSeconds().toString().padStart(2, '0'),
  ].join(':')

  return formattedDate.indexOf('NaN') === -1 ? formattedDate + ' ' + formattedTime : undefined
}

export const getAdminUrl = () => {
  const host = window.location.host
  if (host.includes('localhost') || host.includes('meshbot.co')) {
    return 'https://carbon.meshbot.co/admin#'
  } else if (host.includes('meshify.com')) {
    return 'https://hsbprod.carbon.meshify.com/admin#'
  } else if (host.includes('meshify.co')) {
    return 'https://hsbqa.carbon.meshify.co/admin#'
  } else {
    return 'https://carbon.meshbot.co/admin#'
  }
}
